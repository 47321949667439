import React, { useState } from 'react';
import './YNavBar.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Popup from './Popup';
import Form from './checker';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [popupTrigger, setPopupTrigger] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className="ynavbar">
        <div className="navbar-container">
          <a href="https://linktr.ee/panicofficial_" className="linktree"></a>
          <a href="https://shop.slice.so/store/2160" className="shop"></a>
          <a 
                        className="walletchecker" 
            onClick={() => setPopupTrigger(true)}
          ></a>
          <a href="#" className="mkt"></a>
          <a href="#" className="mint"></a>
        </div>
      </nav>
      <Popup trigger={popupTrigger} setTrigger={setPopupTrigger}>
        <Form />
      </Popup>
    </>
  );
};

export default Navbar;
