import React, { useState, useEffect } from "react";
import Send from "../components/Send Button.png";
import Verification from "../components/Verifying-Button PC.png"; // New verification image
import Success from "../components/Success Button.png";
import DiscordImage from "../components/Discord button PC.png";
import Treasure from "./Treasure PC.png";
import Treasuremobile from "./Treasure Mobile.png";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Form() {
  const [buttonImage, setButtonImage] = useState(Send);
  const [formData, setFormData] = useState({ Username: "", Address: "" });
  const [errors, setErrors] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [discordUser, setDiscordUser] = useState(null);
  const [hasRequiredRole, setHasRequiredRole] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission

  const REQUIRED_ROLE_ID = "1332556224129929226";
  const GUILD_ID = "1207639753751666688";
  const CLIENT_ID = "1333730231470788619";
  const CLIENT_SECRET = "lbTsHmbsus7iiwIYSS5er2YnIxkgejvn";
  const REDIRECT_URI = "https://www.panicnft.xyz/#/form";

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      window.history.replaceState(null, "", window.location.pathname);

      fetch("https://discord.com/api/oauth2/token", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          grant_type: "authorization_code",
          code,
          redirect_uri: REDIRECT_URI,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          const accessToken = data.access_token;
          localStorage.setItem("access_token", accessToken);

          fetch("https://discord.com/api/users/@me", {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
            .then((res) => res.json())
            .then((userData) => {
              setDiscordUser(userData);
              setFormData((prev) => ({ ...prev, Username: userData.username }));

              fetch(
                `https://discord.com/api/guilds/${GUILD_ID}/members/${userData.id}`,
                { headers: { Authorization: `Bearer ${accessToken}` } }
              )
                .then((res) => res.json())
                .then((guildData) => {
                  if (guildData.roles && guildData.roles.includes(REQUIRED_ROLE_ID)) {
                    setHasRequiredRole(true);
                    setIsLoggedIn(true);
                  }
                })
                .catch(console.error);
            })
            .catch(console.error);
        })
        .catch(console.error);
    } else {
      const storedToken = localStorage.getItem("access_token");
      if (storedToken) {
        fetch("https://discord.com/api/users/@me", {
          headers: { Authorization: `Bearer ${storedToken}` },
        })
          .then((res) => res.json())
          .then((userData) => {
            setDiscordUser(userData);
            setFormData((prev) => ({ ...prev, Username: userData.username }));

            fetch(
              `https://discord.com/api/guilds/${GUILD_ID}/members/${userData.id}`,
              { headers: { Authorization: `Bearer ${storedToken}` } }
            )
              .then((res) => res.json())
              .then((guildData) => {
                if (guildData.roles && guildData.roles.includes(REQUIRED_ROLE_ID)) {
                  setHasRequiredRole(true);
                  setIsLoggedIn(true);
                }
              })
              .catch(console.error);
          })
          .catch(console.error);
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Update validation to check for a valid wallet address.
  // In this example, we assume a valid Ethereum address starts with "0x"
  // and is exactly 42 characters long (0x + 40 hex characters)
  const validateForm = () => {
    const newErrors = {};
    if (!formData.Username) newErrors.Username = "Please fill the Username field.";
    
    if (!formData.Address) {
      newErrors.Address = "Provide a valid wallet address";
    } else if (!/^0x[a-fA-F0-9]{40}$/.test(formData.Address)) {
      newErrors.Address = "Provide a valid wallet address";
    }
    return newErrors;
  };

  const sendToDiscordBot = async () => {
    try {
      const response = await fetch(
        "https://safe-bayou-70336-7c0d905053ae.herokuapp.com/submit", // Change to your actual server URL when deployed
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data to Discord bot");
      }

      // On success, change the button image to success and keep it disabled
      setButtonImage(Success);
      alert("Submission sent successfully to Discord!");
    } catch (error) {
      console.error("Error sending to bot server:", error);
      alert("Failed to send submission.");
      // On error, allow the user to try again
      setButtonImage(Send);
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setErrors({});

      // Change button image to the verification state and disable the button
      setButtonImage(Verification);
      setIsSubmitting(true);

      sendToDiscordBot();
    } else {
      setErrors(formErrors);
    }
  };

  const handleLogin = () => {
    const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
      REDIRECT_URI
    )}&response_type=code&scope=identify%20guilds.members.read`;
    window.location.href = authUrl;
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Link to="/" className="close-btn"></Link>
      <div className="user-info">
        {/* Optionally display user information */}
      </div>
      <div className="username">
        <label htmlFor="Username" className="usernametitle">
          Discord Username
        </label>
        <input
          className="usernameinput"
          type="text"
          placeholder="Please press Connect to proceed "
          name="Username"
          size="10"
          value={formData.Username}
          onChange={handleInputChange}
          disabled={!hasRequiredRole}
        />
        {errors.Username && <span className="error">{errors.Username}</span>}
      </div>
      <div className="address">
        <label htmlFor="Address" className="addresstitle">
          Wallet Address
        </label>
        <input
          className="addressinput"
          type="text"
          placeholder="0x..."
          name="Address"
          size="40"
          value={formData.Address}
          onChange={handleInputChange}
        />
        {errors.Address && <span className="error">{errors.Address}</span>}
      </div>
      
      <button
        type="submit"
        style={{
          background: "none",
          border: "none",
          cursor: isSubmitting ? "not-allowed" : "pointer",
          padding: "0",
        }}
        disabled={isSubmitting}  // Disable button during submission
      >
        <img src={buttonImage} alt="Button Icon" className="buttonform" />
      </button>
      
      {!hasRequiredRole && (
        <div>
          <button onClick={handleLogin} className="discord-icon">
            <img src={DiscordImage} alt="Discord Icon" className="discord-image" />
          </button>
        </div>
      )}
      
      <img src={Treasure} alt="Treasure Icon" className="treasure" />
      <img src={Treasuremobile} alt="Treasure Icon" className="treasuremobile" />
    </form>
  );
}

export default Form;
