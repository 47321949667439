import React from "react";
// import './Footer.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useState } from "react";
import Form from "../../components/Form";
import { useNavigate } from "react-router-dom";


function Footer() {

  const navigate = useNavigate();
  // const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className="GcontainerF">
      <div className="GbtnF">
        
        <a href="https://discord.gg/panicportal" className=" iscord"></a>

        <a href="https://x.com/PanicNFTs" className=" Xx"></a>
        <a href="https://warpcast.com/~/channel/panic" className=" wrapcast">
        </a>
      </div>
      
      <div className="GbtnFo">
        <a href="https://opensea.io" className=" opensea"> 
            
        </a>
      <Link to='/AboutUs' className="Abu">
      </Link>
          
        
        <a href="mailto:panic@panicnft.xyz" className="Don"></a>

        {/* <Link to='/QandA' className="QA"></Link> */}
      </div>
          
      <a className="popupbags" onClick={() => navigate("/form")}></a>



      <p className="copywrite">&copy;Hitch2025</p>
    </div>
  );
}

export default Footer;
