import React, { useState } from "react";
import Send from "./Send Button.png";
import Popup from "./Popup";
import "./checker.css";

// Helper function to clean an Ethereum address string:
// Removes whitespace, BOM characters, and zero-width spaces, then normalizes and converts to lower case.
const cleanAddress = (addr) =>
  addr.replace(/[\uFEFF\u200B\s]/g, "").normalize("NFKC").toLowerCase();

// Directly provided FCFS addresses array (add all your addresses as needed)
const fcfsAddresses = [
  "0x00D90b400717A428877693410530fb7cC332bF34",
"0x876A1267a2870865C973714B7FA6cd3623cA10E2",
"0x3a95e5407E32A1CC7f6923F3297aF09D2279bBDC",
"0x5b843c690b670b7c799c2Effd2C2B1a1f80C417B",
"0x4b3DcC15a8ab43128210fE3327BC830c36a15541",
"0x604816E04eFbA25023D3D0C8d4e21B45f86fefD7",
"0xD1908dA667632fca40B497a06E86cAD9705cD2A6",
"0xf81C87FBd6b5801BF3dAb670783a4aC73546CF36",
"0xf8Be29e7F85d85469E5e221440A58a014e7EcD19",
"0x0c05831Ba405bfE7519afd4D342aBE7C80AfBDd7",
"0x511eEc9e081a868D4021624A8c6645F2C64A8042",
"0x3AB430f92b197511f76aC1c19A004CA475d9B101",
"0xD87601e05e8D33871A5f95995A9768bDbf64Cc98",
"0xaDf252F70c96b312C7820E792d0273debBA39DbF",
"0x003c0FBDf029B99298942D4a57B7e816a979dCb0",
"0x45bCaD7337A206BF5Dc761cb7ea54c5CD051AAc1",
"0x5Ff2B96a49e0E97567812d31774c5C88dF633181",
"0x6773a12f7BDCB4410D994fFE14B3F40b10eA2BDb",
"0x62381452209F0660df71Eb726F1986166F3330B3",
"0x929ae42BDe65B1bC5EAdE0D9DcE2fFAc64A8207C",
"0xF0d2ffDF28D8d715c93A4849d1a3143657A585f6",
"0x25A577A021ca9F2376D6487d99AB759C3c60a696",
"0xbbF7cc56A33053e76CcdD36B32e5f292888dAB9d",
"0xC57a68B0dC6d40f6D02F50Fd36E6A1A55058d6eF",
"0x806a1226166Ddb39551b59ed952c56b6373A9D90",
"0x38212E8887FD53905F7946dF521d82383791E6Fc",
"0x9e0a980C59380dB53260989C45027DCcDc2AdEBF",
"0xd8D164C4CB00E6Ce58669510458015c70a51f847",
"0x2f3EDDc34e0893af321794736c5F7A56A9D7107B",
"0xD5A7983848374e237fbcf66E7eD9210EA089E067",
"0x5d3AEaE05154ab1053827a164aE7b00EA510978A",
"0x43B80CE0256EfB72129Ee16cCf4d183E88C2eeEa",
"0x7f4a2940938ac76c9739785768181b538c5abc44",
"0x404a69FC9CF2405efD5D68E0C9daF152218a83aB",
"0xC0Ce7536f8C131E46f4DE5417d6FADaA633D1B73",
"0xc6395248bfB60ea6E13Fb2b9cFf52D5BF1829bbf",
"0x8e3273F1C79027A17996f3e324E20F739BB88782",
"0xde71809abab5123aa17aa8999443d3df641b8dbd",
"0x23cd0601E900f26295a594E121CfaeB8BdDE21e1",
"0xb07ad6655cb6427eab7475b77eb0b73fca540199",
"0xfD124c980AAd7c615B133F422C91Ec488DC74be4",
"0x77fc16b9390eb14941ebb4e32343262f79e0d898",
"0x9BA0821b9fFF826027911AB274A33e3F8180234F",
"0x257434990898a303e2Fb458d1a46b68CDc47754b",
"0x72b378103929D2412Ee63323A152aCf47380a4bF",
"0x47a3474d39451fb1348F732345a476CdCAeBC093",
"0xaC422854180494BFEc89708a28B649719309026d",
"0xf7f532af44b7DCd017f107EA7cc19F87f40c69e9",
"0x0C70071A9134a5EE1FfD6Fd89Ba7efCBCa6663E5",
"0xff9cc883893ad608420df30ab48a651b998ad5f8",
"0x3Cf663e2e9045A5E459636d14Eb62E7189ccA803",
"0x1dcbbd6a427ea59146148cd823aa2fb25cf116c2",
"0x00794b7b900165f452e2b02cc191e6b8b6f8f869",
"0x1d88943e401dda08548863a5e2fde4f25caa93de",
"0xb0f9992c0a860e9Ae6383109176b5dC1f514658B",
"0x1DA59370725067f0b36CF237f5365180d6B13FDC",
"0xa044e8a1535b51ceaad88da7d7bf1296210d5975",
"0xA16EDb4642c282A3467Ba5B25f570809150Fecac",
"0x003d8D2768e9477b67d0eB4835C89520bA529d66",
"0xF187b3466B7527214808b2A3caA43587BF128D91",
"0xd01EE0542e34fC471145BB93e9429b076c3571Fd",
"0x400a8c12A550A85782b8ad034f1cD8532ab2569e",
"0x42541F78D07553130fE74f17B1cF63Ea196f57AE",
"0x288Dcd13c40b9641890b0dFb704B02FEaB6F4Dff",
"0xc472DBD735EB62e0C4cF4808e51B94BAeD511A67",
"0x9135bc89951180648c32F6A94a862B056285b17f",
"0xA0978e2D02667f5b2Eb47efCD4ce5E3a14EF3aE7",
"0x2Fa6273Ec19644443D008Be8F0d0Ed4E0e4143B2",
"0xf23463d2D3Dc16FeAF25057C63101CaF3bC74100",
"0x6A1D6fBB398a6d5AfBD97885f9B7287e18B42950",
"0x158AE2cCD731059BAb553D2c984dBeBd836Fdd10",
"0x8DA676960bC0D68fe0bBaE822e6C0211582c4Fe5",
"0xb220dfcc1d9245a68d3ed7dba2bb6f2d8fec6935",
"0xcdA45b43f9DA2B6D5786FCe468E3365555260354",
"0x28F8ECb6bAAfaCF4818ab5d67CB51fdD21b60E86",
"0xE5316b3295B83ea34340fcb35B9Aa2553E055033",
"0xFab16600DdB446Da2993e7B287EF786E76850b5b",
"0x27aaF8B0c22f76B486cDC81BC07f345147aaFda2",
"0x1a1e5D6E1f284C3590399Ef604d58cd0714A4fE7",
"0xEcCdd95D7d47a5F9B70369617631A4fA7D7eADE7",
"0x3c555E79f4d88Dd2E757C5c509DeD5E1bdDd4E6B",
"0xe3AdcE7545e36b52814602a179DE1Ad772Ae4768",
"0xa9281385b2c989b26a2bdc74a31262992796b34a",
"0xA1A6242C134948cE718f5859E782C8419817C965",
"0x7f2fF998754390cC6aB90E0449Baf5A260313dCC",
"0x227aB5253e460A0b29993F3692478eB5b6Cc11fC",
"0x13D0af67de2fc57689D435e566e82d9cffE76517",
"0x4184bc5E5444F250767E8D33A49817A9B4FB0df3",
"0x6AC5edf5836fb2EE3643FB7EEC06392Fc482b33f",
"0x8c3DD5Fc38F59ac238A7baee81Eed46746e3a801",
"0xD05f605d0DaeDD0aE9B7d5fa75e2fA5A1b88D85f",
"0x0b3c5888096A96B8D1E7C0fFdA4C8F0e2947D70f",
"0x2499412b94dca30a14d4d21e7af1683ec4d079ae",
"0xe723cA6Dbd2fB1aD9823e581433EA9fd3a9E1c2c",
"0xA767a4C0E19CeFceB020993A988189B0c1665462",
"0x30F682b2b7a0e8C72714C0Ca4AD28B40ccEF3fDF",
"0x464720142A0F39E7Da35Be4ED1ef2296f93F7E43",
"0x7217711e86cE4DA8Cd401046eE0A93f40497CD69",
"0x8612d5c8b20169e7E37547410e03f93818641105",
"0xDEFa52ed967433C5ecf0bb122a508D4376d44bDA",
"0xd22aB892e40AE38AE08b7b4dFdDdC865707A093B",
"0xb40e17299e417ea9f93bdd639ccd0f53e615704c",
"0x1Cbb99bBB048EB45f97dDDF05ac7d3Eaad899e35",
"0x15ff07cec014fa3c5337558fe3b454601c958e5f",
"0xa1ac76e7DC86021B84AaCBFf2Cb7D3112ded7b5F",
"0xd0a6e50F3211C9236d5b0401492589Cd602b3E0c",
"0x546Ff34e04fb01e346Fb148Df077a07B1B5544bE",
"0xb251C6583749D9AE85b89f2Ce6e01Ce336Ae81A8",
"0xB4Be85887D68A3dFDd5e9826A5b7744379FD34E4",
"0xec64302e26fcfc16b194030959598f4461d7a9bb",
"0Xbbb8985Db8F1F447C9Bbe65De35E9F043B7Bc1Cf",
"0xeaef082ed5ecd6da6a7aa37df8d5df2a78c856da",
"0x345672cb465e222aa4467e20d58bb607f3338c1e",
"0x6cd8945c37027817844ede5091cf798cf6e27d6e",
"0xb07ad6655cb6427eab7475b77eb0b73fca540199",
"0x38ca1d7302ea6fb565b7303bc0bd23235c516575",
"0x70b9Ad8d89E74210372BA01C22eaa7E537e96cdd",
"0xCe702d9b28fc8b2D12B1D93c75DD70bC1a6141F0",
"0x087ea96baf95f167e72b79ddf089d25458a8a309",
"0x51d713c53f144cce2a8c2b145963ab8bea7cd55c",
"0x24102E187eCff6a49ed76bf628F4A3be3CB2cBb6",
"0x681BfA6dfbFAc033D44dC3eaB177167Be926ef43",
"0xe577318b6BA4DBD73Da4962649226C5A668A3239",
"0x1d194801e07b777F50ca2759C64Ae32d7a3F1E6e",
"0x604A935Fb33145aD3FF609d744eA308AA44BD3A8",
"0xd695F0Afc477928f29C0F3cd042D6e793689D055",
"0xB0d3A527BB67df12Ef368a03d8Bc0C5fF8f06A38",
"0x376D6a29eAcB63162f6cD6A221Cf4320079E188E",
"0x416557D219DB01B1B4ff6cCF03f7D4CC8D530da5",
"0x513eDc205c0239713ade524B6714E3Abb1227423",
"0x8a8d49b69e7c84E0c4D17A75835a4a7e1f236142",
"0xB26Bc48D0f6fE2Aa562cB66694DAC5f65a48c352",
"0xC02ed0279b5eA92c56AF3F31F4e13AeF77cA76b9",
"0xF709C2dF89d3B9ec85eb451Df925a074BeeB1ca1",
"0x7F93958D64311934A4Af91A62f6c96D0168e107a",
"0xCe1A30847fCBd0bdD24011EEFe871ead95CF90b8",
"0x326bfb063B19Bb72cC0016435Fb81895B0ACf540",
"0xf8C63C16a27De3C99314c73b1D4d294CeC35c5AE",
"0x51B0DcCB58bf58869099c3ce1328E2092c61044D",
"0x652735c14Cc4793091b80a28a1f0513CDD31E15E",
"0x429c100f33323C1eC4459B1b498e0C70574B27f6",
"0xAFCD6e9d84B3FC86485605E9e8c31c690e25BF78",
"0xc7a5AAa937513194058848A73BdC5c5E7a54640a",
"0x8A38C054336aD4B9d55dc6C07dB3fCc100B1507A",
"0x75C717aEFfE7F3fB2f5eddC1F2a7BdBAE8AdE720",
"0xee2F09F8D681E001aCD9f75848e5e148C0418701",
"0x38f9fD86FFe9B058Fec8BfFd913BaFaDE5dDc231",
"0x8bc58B313E5C2456b82B56b7A55e182526668A60",
"0x826244ae190b98502c1f74045fB45C649C1c5c6B",
"0x42905d26a1A5491E9d350dCB1e68DCBBF45eB0FD",
"0xB6155B79eD9077d025962F2Ba9d99b9d107574BF",
"0xFE4A5bd5d45783D5a585E08b046C482821e42cf1",
"0x9Af56370d26f87b5A8Ab4c08DE3086A55a169995",
"0x4B8e4A0627CaD99a9ff2d1bF2b188ec6830F51D1",
"0xf0Eb57A9cf26e4a3039DadFAb4411030C73fAbA9",
"0x5496DB6EA99F9E339A96af59BE09876daE79B2BC",
"0xDAeF689e1FD0f2cF1661E2622563b093d39BFb0e",
"0x1B7aC91357d4708c5f97fF7d1B47dC7827D7642a",
"0x432FCb43E69EbCe55964e805485C558241D6eD02",
"0xe3AdcE7545e36b52814602a179DE1Ad772Ae4768",
"0x459e4Ac86D1D1822a9FFa23D0dA038C843658Cc9",
"0xAC8E1B30004bA65515B5780",
"0x680a438e63D1Be66b14D5Fe395F4600da37d4ac1",
"0x06A034dAba3c62A26fD959FF896F7D7f3e9ba2a5",
"0xD0c4f3b618BcaCaD5d01A46c0B35bC6B0AfB6e58",
"0xd8C2B8BBCdE9F420A1D7035a8C908b6F734c4d7c",
"0x6Ba035281f22f19d13C4E230F59413f424e047C9",
"0x44E3B6c84aF7d1998E2790494161a596F8eaB692",
"0x87797149ae603b083e6cd612e0bef222f708fe00",
"0xF0D3f2Fe05075304E8631865B30B74c36F1843F3",
"0xf8B98B47DF846FB8B33452880b1fd1C349DC6eA8",
"0xA07bd49f31Bca4B405a9129C8B908a5c9691AF10",
"0xb8e411c9707cdd4f20a80bb711915e9ea0c5baa4",
"0xC9CB1C1cc333250859316aA2ccd842289255DbFB",
"0xc44B89D67EE8240B1905e3F71917D1792290bE9b",
"0x9725Cc2C279cCa04ed0758070DE2398D8eE5EbdE",
"0x03EF1105B9b42a77a8Db644C40e0D0544276cb74",
"0x3FbdeF41a61643704952d956C3d610309a300167",
"0x0eA4EdCB4E267784608946D6bC39115977639b22",
"0x02056AF5Ab6db07B3B666EAE9652aA08B9B19096",
"0xD4D8465fE9Cef8Dd0995FbbeE87b61811416C506",
"0x035A5a55134a9fa69E7032B109c4E477540c2de8",
"0xc111d69DD4fBf358f3eC90652F773D1af38bc678",
"0xD65E072b3932Ab58dbEEDafd4a681F0d4CE6d834",
"0x8D73C2f26da5939dc7FeCAa2C17E3e80c2f2C7C6",
"0x0bd6001076e4ae5d06d07202b265b47665400325",
"0x272e004b13438451224E38a36eF5A91B9B16e634",
"0x232AAEe8F202A48EAC9c8F2f04074DF8dd2f5F71",
"0x98C4C14f8cc48dBE818D97Cc14bF28645c8211E0",
"0x687a8bfe221f5587ae6da069a31dd23b2a6dab3f",
"0x88a34f26e5cacb6342e722fb6770b18816ebedf6",
"0x084bb55284a1c2Dd672B3861AcD377Ba0e5F04d0",
"0x859A9926A3A4AEf3e4ec539bab1589E9B0296CAe",
"0x8E39c678fc33FEc4B73fCa0E648345a9491963B8",
"0xcd8b841f6cb191c2525d1ab02c4879e3c33300f6",
"0xe62d4254dc6f3e06fc1e81a9ab36f3424a64eed6",
"0x30e0da30b5096302975e85a798cd3f9ccd273d26",
"0x2e8cCE5295fB4e303d75cA130E7284C97f5Ad8f5",
"0xf8d8ff5f3cccd7aa47d60ce451d73568f19690a7",
"0xd7CD646b5788a4ED0178c7DC6Ab2B719ec35647d",
"0x27e18e7a834b7dd5da47efdeefb73217a3e79138",
"0x04ec62080de59a0c7c0226b04398b763d02ed13c",
"0x3fa226a804c1d449cae79c1e04c2a92538d84284",
"0xad188f0d79a5a1ff9c2bf506a20494cd04976545",
"0x7314a02781bad67a6fea9fa6ef6ed485bd7400d7",
"0xa44Ed6fA2331dA3CEA907F2752D2dFbB7cCdBE1c",
"0xe8070540f54007f4bb800653ed5345829bdf11ca",
"0x23b399cDf34D16AFc210F67b71a4766DdD717591",
"0xF476641816f8B5620B5A23C871A2109E67aDb003",
"0x0a0e2f19213dd0712f4740196f38d0c6164a3c92",
"0x27C6c4ab87dd7d3f12BB62aa224ea0d3bA998979",
"0x93Cc6448AE5c1CAbF89CD741603ee5bb06FCB87B",
"0x733d9B312Db9F15fE25925828D65bE19eeFeBE7c",
"0x3566f9E290810b632A3023A8eCF8b9EB834b32e4",
"0x0a0e2f19213dd0712f4740196f38d0c6164a3c92",
"0xd9dbacb96b8e80baf0869e79de7cbcadad562656",
"0x7e498958a827d46a2ce36d2cb90cde3abbec6b4b",
"0xfe538a0ca02e2e289cb46bec4d8ce0813a4037fb",
"0x584832c288cf6f8de965f419d4b1346cce1967f2",
"0x61edbb2142cc6cfda3bcf5ea181d8445817c945b",
"0xc2ba384debecf21708538f91095def09efa69381",
"0xe50cf263b9dca0b83831675f01ccc28bf020a67a",
"0xb3cd1b2500dc1bdd0d104612d99e439b2a844607",
"0x3b4ee38507347ce812f925090a4a2948021d7f3a",
"0xcB47F9F17121568c2CdB48FdC63ef51e0F398fa2",
"0x787982015fc5a8c336b17605878b86f5e0f83fdc",
"0xFc2Db268200257A52585377ed45d0ef002BE80Ae",
"0x733d9B312Db9F15fE25925828D65bE19eeFeBE7c",
"0x12063092ACBf10fA6ce3A405f4457cB80a7fd73d",
"0x54c628a6990F0b2f3046A3cEacE6Eab1e2CBE04b",
"0xBe37629354A35ECa43A8c7F719D2E9603F770826",
"0x676b90f6425121B22c12706B4eBC8d86d0013dDe",
"0xe79c79Ec7a779981B296de439250eE446A78d021",
"0x43460afE0b410B5f295F4e3f1231fa4288352698",
"0x29900546817e26373380ac9e5f988cc4811bb014",
"0x9251418DefDC7b1e66Dc4141FD801AA33AA14d37",
"0x421db0A1ED32cF25f1673907d2B381260EB48633",
"0x514B071EB163a55D3d4f71fA50D40647d630f690",
"0x1CB6ec7754EC4BF3C254C8cB58dF28e9A7D5A1e0",
"0xA81530C3f474f6C72A39ed4ED0A4eA26a03AB937",
"0x7CEF81Ad672F42C2CD4b14ea5E620e7c1D708769",
"0xda24Eb1e65d986069C8e74A4E5F3Bf9Ea9D68fC4",
"0x8543e1b36d34fcdd9fa019e4ecb9827be228c6cb",
"0x1c264873AD6BE7529430C4C3EbdcAAEbB27847C9",
"0x5e5a5851B53690F45c2fD317D4f90718d0561f3d",
"0x1525427f946a332C3eade8Ff4dcaF0c0aa0B4D78",
"0xfb8D6Fd1D5680f4d741046814E76fB5235EB6048",
"0xeFCC1A02C143a29da15d0A92dADf9Cb58416313d",
"0xf0eb57a9cf26e4a3039dadfab4411030c73faba9",
"0xcba7ff2932db07d7e1735a84c8fe2113d8f6c7f3",
"0xF9569179c4B1331246FF97C7DEFCD8dF27De2C9D",
"0xaF0Cb87dB05074cd4f989D2E80F603CBB11C1974",
"0x0D1D5606507b03eDb81488B2700d4b4276ce4f30",
"0x82359081C428D0dc548202260B82ed4917669ecF",
"0xf75ffaa90A5e35D010Dd0f4Cf428DC40e4248B92",
"0x63ebAd49bfFAfeC51E8cE78e386E37aBc6896800",
"0xca3f95ae40abd59326618c76004dccd75e5edf7e",
"0x285aAf425eea14eF8f382180c04FF4ac6e0E1a9e",
"0x125B7EB6E880dAAB1Dc85c671aB95490676f74F9",
"0x6bb08F92969dc9175eaa05B8c42511151B320B7E",
"0xD4A96278317DEcbAb48e6794B4C97482e0e110BF",
"0x561d771999aa0E0602e7716524f319DB7d27D92C",
"0xBb43D8EB97585B9e8EeeC90c342714a95c9E1350",
"0xb3643a5803eb917ae0bb40c6d3932e17558eee39",
"0x083A5ffc2A5724Add8b79383A2e82db6D57d7594",
"0xd06841ccf3Bdee062de72b0bcD36cfFa8315915f",
"0xC95727466F89bbbdA21e04e94E15b39E5Fa68505",
"0xC84795026fE81918cc6cC57cBb71584aF9816f3B",
"0x304B204F69A4D3F2b8dCced3970269dCe8C7B3a1",
"0x20E8eE984Fd2EaFB6f48Edf6491e38db01D7DfC7",
"0x09281640d9C1A66CC260d3030bff6E1fD4804bCD",
"0x3efA8A1CC7ee98FBf33816B4dc5dec3AC034f364",
"0xfaA5c4b79a758856d7bfEEeF088154a546c3B65d",
"0x94D76b836706A301Bd7C9Ca160f8A3cE9DAa3d74",
"0x891Dba9055De5A90025f85d9A57b62A74c8B7138",
"0xeF16Db861f84752CBCBfeF91BbB29B826010DD4a",
"0x2A67Cae9Cd051E3e223eE2acCF5332b132aE91fa",
"0xbBcAfB5b13541BE60FCAd298cA55654f70968309",
"0x0ba9Bc5073b0a44eec82ba4192D9C030EadC3dd4",
"0x2741797CD797a1FE235F6cA68AcD82303DC65255",
"0x30A7ecA881478b9170ae538bDBD81d0aCa24e155",
"0x59ed088F50D0ab3C84bCb85b593E15A2b889f3f0",
"0x6C6567cb7D07a2AFC554b87d87867728B1E3452c",
"0x876A1267a2870865C973714B7FA6cd3623cA10E2",
"0x4C04048Ef75182c86aecF92904e84cc3616aAECB",
"0x4063987704C02099574F14e9d8a7B637df1Bd3ef",
"0x63eC40ffD185e7DE69f0922478f3Ad98cbeeDE9A",
"0xdF4d2f6038494184e20B49CBf8aCc407986EBeC8",
"0xCe0228F505380AE6a0e69a55CA2A9e0b3d8edF99",
"0x70465c75182171805Ef81F17727a6CD62128B232",
"0x83f892c0CD758f785bdd6bBAbe9DD802CDb5fb9C",
"0xE02599474556684E9Cb7aaCe23af278E20ed24d6",
"0xa6b467D248d6Fa183b09a875A664e5e905094162",
"0xc96735eecd8a37d86221e868fd51341f001476d6",
"0x56f9f1efa72E1E4BA56E74574d45C5A43624960A",
"0x53B29E4aE7730e770CBE7cD27A1292bE67Dc4F34",
"0x83Baa01051103079cD885c92d8425E6F59b05188",
"0xFdc924FbE0FD130406242A828E8c7D564f57ee9b",
"0xd2b617F950bC6a9bbe9DDb3df61dAC4450fb6Bf4",
"0x749E26AbFd6Ef58651f45166a1e1f252160b6181",
"0xF32E1cFc6F012F9104C50ca3F6fA552F5641f798",
"0x0A473Ff2851fCE7D76b558f119154c8B4DeC6F84",
"0x93E3DB18EE152745E009a566276169b3670eCfD1",
"0xAf7F14014C215e88d56374Bb0c35191B21391BeB",
"0x06FDD770e58B3B6bbE7CDeA1865CaF2ed98Ce7Ef",
"0xA7964d31fcB2DDe6cAE43A71205299DeADEf90bb",
"0x345222ecc676dB61A6cE5701931748D758aeDA87",
"0xA8568a7f1ae6F74E21De601f07aF71e9493C77A0",
"0x1eD7e645Cf1cbA7c55B0A68d6ba8363a360be165",
"0xD76be6AD50BCE0d705345A89BCA883C0E3F20b25",
"0xF451e0Be8CEE49735487b1a7259FB4fE5a7AD32e",
"0xeBf0BC4a43dE99Ca48934859A32A7E23C531522B",
"0x775a1287FEBfEA248d8e71012D1534CaeF23582D",
"0x7f0c8747a728a00C4AF2feD67d63b35bDd4d85B0",
"0xC0583C1D2C9Af167966FaEf99Ce925DDDDadCc14",
"0x5Ebf7046F039435bF723A5D485f73Ce9a4d5d18E",
"0xA08c6fffA31262ed87F11e208Bc0439D76f1fdad",
"0x23Cd3b29F66211d3E0eca64eBF00b6343E209Ad2",
"0x51a07130d8B82DAcB8A24279d77de9c932D398cE",
"0x322CAb81198FDC5c805De235b83cedd9916B117e",
"0xaaD1F7Ea1bc2083fc1959dB28581556c636A1Bb9",
"0x30183a0C3D024E6B86B1A3F7B1b0eE184Eb3c6BF",
"0xEFc6FdaD464cEfFF2146098508108fb563674221",
"0x4b5a40370cb8F56621B66e5097Fc79a3F44B914A",
"0xCaF4D9409877b5008FBF963069908101D8612fF8",
"0x8D6D3Db19995fc191e2b146eFec459F1404E18E1",
"0x6BEbc74FE5821502354F13350f1CB019208dF3ce",
"0x9294FF89BB5f22422e41598152dB9A6bD13C45F4",
"0x44488D50dBA391e564E7cb3094a4c2dCb5e9b1fB",
"0x7c3254f9F48c7c54896A41de1Af463F1b151cf54",
"0x57e766997eD89eC496fdF3FA315D12bc2aE87E63",
"0xDe7A38A6e2458f73e17BA436b748520ee01A6D67",
"0xc19a96F445A6f66Aa4c240de200811b2e6107659",
"0x13cc89605F9A7a5364C89DeB522dF833B385e5F8",
"0x7181c8aA61BB0527B48E194f882C638CE2B11911",
"0xC161dcC505f5fC4b8748912228b14aA309b43Fcb",
"0x0449Dfd7bfbce5050f592e53A2a60817537c3b02",
"0x65fC4Fb27719b3d37020068D257F27770Abd41a5",
"0x2Fc73Fa85B2e4EbbF808Aee9233D18D1C636A2Ac",
"0xebbf8d1a6fd2912a0b5d82a5acca3424d9b40a11",
"0x466Ba4665f63a0CB2F08D0aCf4E363AfFA251efd",
"0x18Cee7f8766D915a51Fa5A7Cb0d662E86F659fC5",
"0x68b5350688C5fFb993e72Af22C18Cce19bdB3D44",
"0x972aa5413B84b92b066E5FDAf72b3CF32A7F7425",
"0xDAcD4012685E4C989Da3B49c17E34778A196fCba",
"0x830785D5878D7E949E120fdE11ba89f8EA70661e",
"0xC28B4A59FA6E9E1A74005a3F9738B8d6C6c4Ab73",
"0x2Ef5BA2190672B573c5F59Dbb131CcaB943d0A87",
"0x7Dd5b867DE05314358e844E46ad0e6E45B33D0C9",
"0x08C78204ACe89bb276d80f49FBEe126ebcF31570",
"0xF6974d8d4f20c67Bf0604dA9B1D499239b0853cC",
"0x2d88d72433b005b70342ef6D4D05C68040F21476",
"0x44a94c4c502fdb077046e33448b9725d4be210c1",
"0x5f19e28c6cbB6da14363074eEdfEc3e49d4e8C47",
"0xBC6444A714ECFB52ad7266788F754AC69192eBb9",
"0x672091ac2dd65d238603618f962a2b6e9340d0f5",
"0xADC2839F0E481349aC9C8c9D53d5C928C44CC84a",
"0x3EA06201F8F7dc29BA776E6Ffcdf1eF77528e8B2",
"0x3Aa77Ee4F2F7b1a8Fc651F2DCa64363303d8eEB8",
"0xd31C1a7F94e36ddf09E15f62e06d6dFa5398856E",
"0x2CBF3eC03c6197374154d32BB517fc1A551e6E68",
"0x503b0ff3381e1F2159E22cC05990bb9D81D64702",
"0xa86f5324129c34312187cde5b42fe283fc493fd8",
"0x3f90f2d584f29e4826f1e65b80e44b40f2a65930",
"0x9ee62b2d77ba48639f011e13740f73d2cef661c4",
"0xb75438d1914DD0d6F9d4F90ee259606ecCaFA2E2",
"0xAE2f9Df298e5Bb263d7A8C471bD6ebB6023B82a4",
"0xA658a43031f145Bdb9A5D441a87783930942FBbA",
"0x915aaBD2E38825dfd4eCAE099A7B28dA903Eb5A0",
"0x3A096c105c14A409dDf341b8e9520F4F1E82e2B0",
"0x8cd0d1074012c078a93EC386562E8aD942589e8B",
"0x97FAd38042afd569464FF40ECc4353267fA95D68",
"0x730100728bc596a72E9F06661b4A5d664a9E4A6e",
"0x57e8550e14fA3D7c78c031380270dc04B2D777fc",
"0x09B1169e6820Fa44a36A433df1FB4CE966e1D6a9",
"0x39Bb9b69aB379dAe75C03878Ae8F58274f3631FA",
"0x73F626b5dDd5642085A03D9C0384e487Ac877D4D",
"0xeFB98b73429A4436Bae7baAdf3805390Eee710f8",
"0x7F97962e893067e04fB63C5e06A9f7f2c5642eDe",
"0x46191dBb712063d9548DD3bFeb24CD92cF75b198",
"0x0CbC1E7135638c0af9febDb15Dd2C1e80Fd1002D",
"0x72b88c38BE8ADb357bf4581609988af7083e0Ef2",
"0xc2523dED5Ed0bbBC0DC533F18719aAB5Da2CD6a2",
"0x9722B36635a1916ef6F40003221b295C6Ec260E6",
"0xae5cBE3900fFD877735A8bD20e60763abD74e737",
"0x9160dc8b61EeaaC314C1B2D72F52D19377C4d66e",
"0x435b8DFCD42153aabFc433ec96FeaD8D1beDFC78",
"0xdc5Dd3c27bBeCD5C65474dD567E4D9F35015cB4D",
"0xA194557E55254e6251986982E63A0C813ed18758",
"0xec3843d0F33a31900Ac2EA6207F04631f67d8F7F",
"0x49239914dE41F80d01604C52AC39E61B284c85E9",
"0x969BB14415BC18DC49F929c34c85Dd6ac23dD474",
"0x4c9f19C2c767fE7BCF1D63aC8a05C1a49Bb7a5d2",
"0x682EE01db9D66BBF9F9254a8Be9c2BCdbB293a8E",
"0x78548c8C905fD4f2c3D9e751b364638Eeb2AB3E4",
"0x15aF8559Ad8f85374279668f10Eb01119B538d8c",
"0xa71885755301AC2c99E92C482e3156780c75B3d2",
"0x3E6bFD56b8A429826822A35FAEf6B01dFe402fEa",
"0x2767324b8B9DaE959bbb5bE1024a2a9D63114c76",
"0x25EF9E3B94435aC6fB322BCcc2DE3e208B3e8A56",
"0x95139C26E8bAc5aD0abfDD92a2Adf8D2Bf34f43D",
"0xC1638059FA98230590A09f82f8fe195F4100E97a",
"0xBA045c0109bD91371184b0C267fC1567F85ee0fc",
"0x7af61798710e48B50B8358D958370Ee82FbEf582",
"0x8368F6301849ca7f8D9A1496702596921654f623",
"0x4324e2fc33a6EaE8C44FC17E2995FD4AED346f2e",
"0xA9bC66E47B20aa7C0889aede1836736238E5aDE5",
"0x799a343A127E45bA2001CDc95747baB4CBe5415E",
"0xDdCd2f19D5D21dc8041EeA839C3B0640B4828896",
"0x50E8ef738cb78d9EBD3272DA61a31B290A1dfD6D",
"0x255954660709f2Db8148c0ACAc835ebBE9d3f7fB",
"0xb12D95a0bd29BB6A9Cb91c3e9C2E2Fcb060Fd123",
"0x1cf1066d7aBf802eA3E13ba04173d46BDE747E48",
"0xF32E1cFc6F012F9104C50ca3F6fA552F5641f798",
"0xf7B91dD052d54BdDDA19F999FBd5D51190532CA6",
"0x52A09673ed2bE671ed6fBec9bE5420bdE7535d6B",
"0x9ce81DceBFcEBDd2A0fBe95Be3A3b16E0d47eEF4",
"0xD1c3c89676f24749f8574880AE33f81fFFCAA7E7",
"0x315617A315f8c18D07F610914c8DEaCa672D2679",
"0x77703e4a75cae77ab54c4c4079801e6ebe275495",
"0x19feAf32733E55a20EFA82c2B7b01b8E4d3C6F4D",
"0xc1A095597A3024940fDE7daD97347AeF22D8c9fe",
"0xd2975983c4497FCD97d595E4555F357CDFB1c081",
"0xD217626D506193850982a80e2FBA34cFBf7B4BDa",
"0xD217626D506193850982a80e2FBA34cFBf7B4BDa",
"0x80921ffE8C4EFB2A63D62C1d76eA4b9047b26357",
"0xB6E0Fca0eAF22008a117eBB8BCf351536D37Fc46",
"0xAAb0336F31F3103Feb38f7388A907201E7e82654",
"0x2b0d83Ca9b649E241Cf9Ba8faFC76e3De16434EC",
"0x37ceD58BF1236bf0320183af2907Bc486e2BCf41",
"0x58367d36c2E4EFf07A54217e212Dc18559D0373f",
"0xD1c3c89676f24749f8574880AE33f81fFFCAA7E7",
"0x4B078a6A7026C32D2D6Aff763E2F37336cf552Dd",
"0x0911e15123d7F83Dc51b1b0608dDB62956e83Efd",
"0x3d609a3c65f59a7d5275d00724f6b63d151a007b",
"0xe08216d353a968f660ac9096ab41b32745330ea6",
"0xa4B2B1fDd2C1B072202f16E812B46EdE09f526f4",
"0xDeeFCbd38fBf69a089ca815a0d05CDCfE9577A36",
"0x867cBCB09d9bCBA48c5ceB3ec29D67A18482a3D8",
"0x1192A8Ac2136DD376A28452382c1BD2d9047B343",
"0xbCF2a20915CB202176a7D4cD3511cC0c45B1BC15",
"0x10734CFeC0681f8377FFEeC803EDF2E9855D46dd",
"0xe3424d0e8B7852b77d87f240Eb765D1E8e4C643d",
"0xa4f09F45cDD0Dc74BCbF304a18AC9D3542FB83E9",
"0x7457bc9Df112575B06fD2C7D12948fb28c575B99",
"0xC47D3f64c3351067CA56CB8475BFbA044A51d6d7",
"0xCb658f4eB1765B4d68004eA5978c51816B269c33",
"0xb2fdbd2497ead24418835fbcb0c40348ba11e742",
"0x4c82f3e837c30d86d12ba774ffc45431a348c350",
"0x256a87b0e1608d56df391f3e11c0e7e863033ddb",
"0x94cb1a2fef8ce17473f2d558e65949110e5eb132",
"0xc2e85226d6a3752ecb89594f6a4de06042cba812",
"0x5f8c446d3a7150740a99041ea2feb7ce49fdc5cf",
"0x41bef496680f8aed2fe401b3a783b0202ccc9aba",
"0x22eb50bd1ee59a24bf55d671824be5d0c3a8a215",
"0xcff520e164a5f12f07dd01c2a3da078bad389dfa",
"0x901dbE5b89efCe99e66893a8B40DebF7E2D6f208",
"0xD781Bb234b7790467C742e8d158D5C8a93a9AeEA",
"0x41BeF496680f8Aed2fe401B3A783B0202ccC9abA",
"0x25BB190Da7F60E00bf26587cd12F0B3448B6d5d7",
"0x93409dd0a667D9c981A92e1F7f5571B613d676D3",
"0xEE1d5127ABccCe0b15b6913AA843F056d8540686",
"0xf5b6F869D96164c323C12b7ba8739B2fb20e8EDc",
"0x9294FF89BB5f22422e41598152dB9A6bD13C45F4",
"0xdf54db99877b2566a4b7c164c069923C06D3DD9B",
"0xfe6a31f5610c07a2a96e47a089f0248973f5458a",
"0x03CBb66885D775833204aB69D6038DC87E686DA3",
"0xc724A95bCe4bc15c79421DD615D04416709D3a51",
"0xA7B4AB2634d14cfA3d678FC1D5b06cAA95634ec0",
"0x9729e5e7b6bb1dc06c1c26814f67befd0f52b622",
"0xAAb0336F31F3103Feb38f7388A907201E7e82654",
"0xDa344a6fb32518086F1856A0C95A7F93458E669E",
"0x7E60d9A95f747974b564D3C1F876A8505a6D0Cae",
"0x04339d9f833001a75A4F6Cd2d02e47cCf5A7F06A",
"0xE6D1401a53Ada23fFc4F4A352E672208445cdF49",
"0x7ebb7172751e86e51e3a776f22863243bc36367b",
"0x6A601dA66FeCddaE0Eb929BA6f21d20E07CfBD7f",
"0x54eDF559433174fb71A83bA35b8B94012a50C9F3",
"0x1bC74123FD4cf53b49455d4A90FAc3Ab4fCdcE46",
"0xDD2b53C66B42e1B793d4cB1829F7A3326146a453",
"0x90E78c1794043b37312B8f2291fC5714EeB8ae1b",
"0x84EB19F0376e8188dd254895281205310111dBBA",
"0x548a3F61F95dF5Ca026C6e53452f5AE7E0294e5D",
"0x667B8F9f730343Db6F1Af6fe5918CE9220b81D12",
"0x34C9F402b1Cf5E738D49986d7bd3DaA11DCD2E4a",
"0x53AF8E051E738Faa0bde2eAD8f369D97c0373b97",
"0xf83d314c3cfdfee36c83a7ecefb3f90927cdb2ca",
"0xa1efaf22d466240ae6a20c1036166c9d605798d7",
"0xaca409c25a1dda611c8c988d3ce85623554d3718",
"0x633a8a7662cbfd6e43de13967d36d693c7e23869",
"0xc8e4ce318aeda654fcdbc46ec1494f6063d18bd4",
"0x4da60444b122371e0b0a10859fdeb66aaac9d8a9",
"0xa8fe9024281df0bf673a12f0b78fa954abe548c2",
"0xBbAF76CF1ED3E74ACbFe463C26e4c07E6fe6C864",
"0x707371f9589204d3c7d4e290222af51818cc9b0f",
"0xa47298092d821368047b446e2cc1ed9979258125",
"0xe20ba5daa3909abf660b8dbc5ccff976b932a2c2",
"0x015a06a09Fe1d36A2ae1bb479D9D9429ECAa2251",
"0x8d1f264f54fa9370b3b79a8d67c846a5fde80d60",
"0xc8e4ce318aeda654fcdbc46ec1494f6063d18bd4",
"0xee12f8156f7669ced582948d74c17065cf742141",
"0x27e18e7a834b7dd5da47efdeefb73217a3e79138",
"0xa8fe9024281df0bf673a12f0b78fa954abe548c2",
"0x787982015fc5a8c336b17605878b86f5e0f83fdc",
"0x97867e346034b261649bc5e9d96265288a7fc6e2",
"0xeba30865c3b6f79f9087dc35531de5cf462e375f",
"0x4aBd89048b7E4774e4A13e2B69d5E4e88e879680",
"0x6b6CD672DE83B53Dd2f11C43657Dc2A68dbF5b00",
"0xb35A8a9c825E61eaed7564D9f7538bb802F08bc5",
"0x92CF1AEC23A1faF66b294f68194Dd442Cf05764A",
"0x5D60886a6018088DbE8ff85E6B438ae409C7D193",
"0xa02632Ff0B80C02497cF2eB28524E0e26d03a46c",
"0x9A7F36a5cB24d7FafDdFb48EB89922e2C13C5D7A",
"0xe6DD2e2AB8ef62464F755326ef8673Fa69F3D1a3",
"0x140Db20bE7C5fC554dF26A27ED73c4bBAfCc85C5",
"0xb1e271953e35283aA187Aa5852586EC8f62B29bb",
"0x04339d9f833001a75A4F6Cd2d02e47cCf5A7F06A",
"0xC20d87ac0EF6DBBC25d698993890fe09126385F3",
"0x693d23aF892A4A9595eA02FD975C948259bA715e",
"0xe62234a552C6696786d29F02E96aB4aCDe7398fC",
"0x9B9dBf521DE51d40BE118158634Bab4CA882FF6B",
"0x64df297348A492Fb9728534CbC42313712b1Ad4A",
"0x05Ba3a85f9638a8ED17AF97C336734500a72e1eD",
"0x5fb7A33A04bfECA8B49e76A70737671367982064",
"0x8eaf0ecbc3619479f800273959668dbd0419b04a",
"0x5562846CD68ffE188f0cC0C9C060E1b601FA6466",
"0x92c3c5a674aa0340796a25c45c0a27af02bf6532",
"0x20a289ca1a74bdc1dd9c8ea10cc53d7aa5a71e18",
"0xe5ef5b239b94b2b5983df0be1b6a228284d6514c",
"0x4c73b8ceb63779d7c812a93265cc1f38965d8f24",
"0xdfBDeEaFaDd87734dd0A34011FFf935Be482000f",
"0x9210672288266e9be1e04a7862ff6ce93176b370",
"0x8a868d3044c1c7eaf414290082f818157cc4b700",
"0xbebb6b6829e419a97ca594a404c17c1ba0727cbb",
"0x77DFAc39546116c70d616460Fd7BC38Ff4c97ecC",
"0xc96241a245FDC66858ADdBD59557b31064aB4dAD",
"0x6cFBaa1480E5E47c323b285b87De0bC5793D22C3",
"0x88e7A81FA49756A832FedbEd304049986495dECF",
"0x007314f9a01b0b04fd7f204bc8b07906e053c354",
"0x7423e69B3DDC0F8D209CA71222577dDb73C7F579",
"0x52dfEdD0F723Bb075D7a347a52cB423656C7Ae0F",
"0xcAeb2B22E83Fb806E9dA0C907011Fd0405Ce542e",
"0xE49308E29666a9186f360348951Ac5Ef010a84Df",
"0x470E59866552A49F15Dd88259c0fC9011f28Bb04",
"0x6C1291D9845f9c00B5cf89f96B69d8F75E2CA3C8",
"0xB8FD9ba9fF441D1d790Bb79d16034318F69B1f24",
"0xA19c4304761A5556DECd4fa3053C2d0350bb231B",
"0x256f74d3F7B3CbBD309eA67e411B1e29c0693009",
"0x9161da22e930e0133b25acc33c86e2db2a41050e",
"0x660685f937318411fd9792464b037d485f6dfbfc",
"0xAa6E2A09Eb9313Be5A4894596bA3a37f66634Ab8",
"0x6cd093bFF25AF011b3b6AB024Ffeaad211BdF6cA",
"0xC8870a07B3FFA5C726d3971045fA4f794FcbDD5E",
"0x94B491C1921eb9973D7348602Ec2258677Ad383a",
"0xDC5bb135C7F998Cb138912EF9f1f523daA5A1ECE",
"0x17198ea3652ee7fede0d9cfe1448d2383e9d0be9",
"0xb0ec79b124d341e95c02657d708dbf7bea2e8e96",
"0xc1b05756d37a966f7417853dc593af26e622f8f1",
"0x9210f2fc568720ffc76b20dbc769a1be47b35f4e",
"0x29826c03aca5d709eaaead9d211b6564f788c593",
"0xcfa32f82ed512ff44443C291633Af2d2fA9B84EC",
"0x5007806CE47698dEB0ba06fA49e5D37b1665AE4A",
"0x5eb48f79280ee316e416fa07fe3aecc807cb360c",
"0x4ffa8da2095e6b9c4fbae995aa291e0c85b0ee2d",
"0xf83d314c3cfdfee36c83a7ecefb3f90927cdb2ca",
"0x1646b08313d69a282c08b1723c4cd161933bd172",
"0xC6b86eB995c72B16B90075fC33cc63f30d4dA7Fb",
"0x4dFc2F244A463F3f0CAA75024437eCaA0854b7cE",
"0xdab784a8b520b16db9cf9698544268031c95a6d4",
"0x273246d367EF4Fe42D715e49965ae78B45966aEe",
"0xf021788bc8C958d7fa418eB6325cC74F42825300",
"0x73B41FAfc67fbee0Afd35EAEAba76e7468083f07",
"0xd2Ec3B5885e67B313c6Cf6f6699d2562481cC9cf",
"0xe83366A625F1E7374Bd76E631010207EDBc6d194",
"0x3f3fdD1Ee0653C12eFfE705bFFc2C49a640c68dc",
"0x2F193ba5cbac30CBfc875962B1eD5605ea391607",
"0xF17ab7eBd06F948F06Cf91f5a593cEe5537F2dF0",
"0x226997d88C1FeAe2F1bf0D0d7bB287E1E4135471",
"0x551791BcA7aD39B62f2e2DA6ca31C178E0C0647B",
"0x1C699068d6d11d1266829CE89366F3Ac378f2f9F",
"0x2659f6600F2C25A58449Bb6cEBa7857721F56955",
"0x8Bfef35854746608bA7a940456a957d906D25263",
"0x483cA9370064bA03200faDE3b37603EB8cf464c6",
"0xe0F9B8973806822069a6278bE05c8aB2bb793454",
"0x49Dc75a57d936e806393389ee713646F467FBEF0",
"0x1294332C03933C770A0D91ADC7E0F1FccC7476b9",
"0x69A18bA4178850cd7bf0EE1c15c9891B9F4458CA",
"0x7BE5a1c4EB2D8fc614367e732336C5a338862b21",
"0x1ACfE5C5638Dfe9f8074072898e45cc752EFCb85",
"0x7041BB20EF5A0B9291893BF23f9901B7F763B46b",
"0xba3b6831273c6c3dd6b6ceeaad7cd9ad0d4f1626",
"0x843fe06fa33209f30a41756d418c7dfd1fdb0f00",
"0x2C81419fF43C4500483438B817dd35a939eFF68E",
"0x0234caec697b283df7fa46eb206c361a75d1e9ba",
"0x45d957d741f3deb8fba1a56830d865a3253b9050",
"0xf2851c3316068f88624819d4424ba600b7d47b06",
"0xad188f0d79a5a1ff9c2bf506a20494cd04976545",
"0x96d07052b26bdeff820c0fa2065e00db32b79b17",
"0x7aa7601e95d40124e59e9e5c2430ebfe1eed74b2",
"0x5Fa0A5cbfc21E64de78C872C01607aF3B6da2B48",
"0xbC73AadA56bF273e7ac23068e64e4100B1aeeaD5",
"0x506C12f649EE2C179d616F4ea13e9FbDBCf4F91d",
"0x247e7de38627705bcee9dbda1289b9c19401850f",
"0xaca409c25a1dda611c8c988d3ce85623554d3718",
"0xbded2929056380cbdac076994d009c193e9152f9",
"0x290953BCbeC0fE49BD191f4d3c6073Da3D59Be54",
"0x135Ca7613886269E9BB3D92fb71989e66940D61C",
"0xe3996F0E6c729CaaeF1F38B619ECD32127811bce",
"0xB6a39859396b8AB3e0bA4d341f92f9ec50B684dE",
"0x28bf2175262efe5385f12bcd06cf34072cb5870a",
"0xA02648C014c94C31CbCa5c225d13Fec7eE70DE4d",
"0x5FbfDB4353847bc7231CeCC2Ea0dc4E81804D93d",
"0x6511a14e175cfec1559d14a726b983eb03ec524d",
"0x6a9fa33b2b5f8efecb2d087174ef3ae343f72492",
"0xBc50200f92D25FD18E86D8510Fd0540295427B35",
"0x5b245AFaA7B4CBBb464E50c253C6c72D5948a79E",
"0xeAaA984f04D1E461317bcDc9b53585821718D5D6",
"0x04D18DDE094dF633c95f2669AB451d44399de549",
"0x99b0Ee8B91F0D1E7a32947df149Ad07fDaDFC8ca",
"0x7FFd72a0124a279bC021aF410cb337F14fd0dBAD",
"0x4Cd15F3755EcE14aac2843519E56369178851DFb",
"0x4cc8f688eeffe8afad4831280a71470ba02ed8f1",
"0x901dbE5b89efCe99e66893a8B40DebF7E2D6f208",
"0x09b548BB2C3Dc4476eFd89C3452BcdF3C8C9c3AD",
"0xE05b29694F9aFe6CEa4Bba87d8D4bbe1eA8A3C5A",
"0x19C4F8d95f14540e7Eeff9615C2448a493a9c095",
"0x0132a395575679574683468c54Cde7ED3a4BcDF2",
"0x9af4b5d4304d9591B87E08F7c8F18deDbcC6ed47",
"0x51eA40C9da3C65956033Db2c75E7eafE687E2A52",
"0x1e494AfB1DCBb194295338CA915a6F0E5FC62FA4",
"0x36E4538489d1Cc304951e0E0Ad7458fEa2a042ed",
"0x5820D1D8A984ff5f6061299a33F5d7878e6d2B83",
"0x863eCF0d84a1ee5e9c709aDA63F9645CEdc22234",
"0x9b7103844b0c7c8bff5e41599ba0382d34515eb9",
"0x93FBc4B5cCaAc37ED4596314F1fa7C18b61722E6",
"0xe0f4F58C851a182F7632219A2AF89EB91D184F8C",
"0xE9F337dF3B4A2C3cFc700c07091b895B90c26025",
"0x9a38a56266dA50C122e45F0Be4edB94692433455",
"0x4B6A535DfbBd7Bc4618F002Cd5441602F6004896",
"0x2c8607bBe4B8e26fe7C1aEcfb17A6d771838Ba4f",
"0xe517D2414312CB547CF2cCBa66b583E8059567A8",
"0x5C5DB6c52A70359f8266efaD465f4D5da3cbbFdE",
"0xa5F3e1410fCEBA7A28042f881E9a63B461C291e1",
"0x5a6741B548e6656286B808B0610C0A0df9Ed0ff9",
"0x08E8d4A308c410F42AbfC31C6195a3B2C3138Cd6",
"0x4c9f19C2c767fE7BCF1D63aC8a05C1a49Bb7a5d2",
"0x6AE869987c4f5D441Afad5dbA1c074AF37ae6041",
"0x0bCFB8EC93b68bEF409A15cEeD51e4a092c84d03",
"0x89a8dB06cEd4c9E5503C4Ad17B188f8470d3A03A",
"0x0b770f824197b38EE41653B4dA09b7928C02DD77",
"0x5Ee5CB77B6f175952F7307d3d37129E8aB2A23B6",
"0x3C20c9bA1C639694af7F43e41672F98C49E62716",
"0xA3536DE479B8205E2b719AA3cD6072A35899AFb2",
"0x37D6931b26bF08Cd07eb7559bAc963b6458A3106",
"0xC1219Eb86CBF6E622d7d2F3bC111aa87012045E9",
"0xb08c12e0f482157e835fb85f0f46e27d45fc8e23",
"0x172ffEAe39330Dd651ADf9fa9391620483d85F38",
"0x43fb93f9a586a7ef3432deafd6854725ba2636dc",
"0x0bf649eEbdb06a5bC886847ccBbD6299E6F1DEe7",
"0x0c597541a43ace54fb5ca4916696bc533f683469",
"0xbbF729A331e39621DD886CB7Ab4A1E739950D8F5",
"0xa36D25a86A340a769b4C05CD44D9b068C668c55f",
"0x7E447A43b3bdF7BD9f9a515f496B9aCc7994e477",
"0x6827eE159Cd2b0C4eb33A13827290255c56BC2BD",
"0x5C5D01CCfF5A2259D4B346B842Ee53c131Aa6714",
"0x6cfa7c376f975b20f26eee1c9b349f70e26eabef",
"0xce7e4ef5fb58d434b818c485bb56a84f884aa538",
"0x3f1e67fae0d7da43107f403f658498a628c14f37",
"0x12fc8b743fc7460c867c905beaaf17241ec86c10",
"0x281d502F407F8fE61142644d5d9473ec1B2fad61",
"0xF26f8B7387865F4EF3eB012Fbb3972309Be0912F",
"0x1a2c19952602fccde733ffe429798ad0f58d30d6",
"0x18b8c4a360b242b5ace2bf9ebc68ad05152c4d1e",
"0x697208c7dfca0f3df53d998f3673e6654e9d9f6b",
"0x75DD1e1d0Edd6463e63c441EA437FA598471Eaf1",
"0x47208893d4603a4690F51B21b55A4D3574e7eba4",
"0xb4a97fbd80fee137eff580067451ed2bb186c852",
"0xa4Ab04929449e808FddE215144926c7E04B534bA",
"0x6d22a5eb4ea31aa602e654ab241ce848ec046487",
"0x4C44C6372d580D531DbC9021a01659eb2CD2F651",
"0x10E675D273fA3071e9B806b6999adc6e071e010d",
"0x561d771999aa0E0602e7716524f319DB7d27D92C",
"0x194d2Ff0394E9bea4554Fc4D171f5A92145d2D2D",
"0x77b966275BfC8ebB9ad2aC08e5f19eBa4dD8C3e3",
"0xbd8609d6a960c422c60c8943b3cd7c010549d0ce",
"0x6F21EdF36B56471EbF429784fD685B9A647960DF",
"0xA1E8965EBfeeA60034B3568e096162FB27852e39",
"0x553Ee25BAD19cd9A4dCA18b1b86CcCBE70fa75c5",
"0x7310F0186B9bb8A57ba7D34a7A14d796540B0FdA",
"0x72fB298349273B04a4D0A32D86364ECeaC59008f",
"0x1136eb9f8be11c284e328a612500a6e2e96d1864",
"0xDe5EfFEf35B45c1CDe7Bfc73b8233bC45f3e0159",
"0xa8E9388f595780F825EFc018264bcEDE6DDB8E2d",
"0x39603299f155bdf339bc5bdc0dd6a9eff801c402",
"0x09bf972d2f0fce8caf639930f852c70439de72c6",
"0x3ad0f57f006b5a060d4631df7a996d7a188d9452",
"0x381E0aA5965Dd44Ca0920577506Bd1CC5677BADB",
"0xb681379519fd8EAb67E43693055637Dcb2e94679",
"0x244f3df4ba4606a4c726845B66Fc0ec67484f8Cd",
"0x32Ff3E7518E14269681f7a56586f902966249608",
"0x3E28338529ACAd8566C507AEBC38675D2168316b",
"0xDdBeA044d0910dc32d6829D3AB319360Db15Ba78",
"0x0F615319D7CeeD5801faF6b13C9034DE9223a3eC",
"0xCC54F665A8C24B527958DC7776ac231008f16543",
"0x59348052cd278e06d2a899376760e593220f0def",
"0x5686598390c45144AB3Bc02E05158E17D0Af8864",
"0xAac3CD65135f22C2B2F5297f7C7E316B49fFE4E6",
"0x8e1f7a6315aB27B351055823A30168524B8c0f31",
"0x28E78Ee38c51D8531073498c83c9fCa48fc8E748",
"0xac821294e5341DF05aCbe73408e9DAE72C34F101",
"0x08ef8530b61405216dCa143a3de7dd44647cD9B6",
"0xC3bC472Ea7b3Bd2a6e0C11FA9f69dD68169803fb",
"0xa36c69FbFfE76eFf2A5746aa8021371949fCdaF2",
"0xB7D08EB7E587091d9e8dA4A653B760500bF95F11",
"0xDdA76068c60b331082C13da9ACCc03F1b96Ea7F7",
"0x0d373C746D4943A724941FF2C6b27E65c66FBd6F",
"0x751e0e4457df078563b7Be94F5bA9F97a36A4f55",
"0xd154879282d1Ba8107C4d5cAF31D78C77CB7dE3e",
"0x69b9EDf41b684143f5cB5c98218Ed58a535Cc45c",
"0x08eF2E213ca452A8246c6C1fB3deF440981bfd87",
"0xB12C863cb265b61Aaa78da32739bCa2F81512501",
"0x1DA66e035f21ADD7aA949EED9944Feae4D552072",
"0xFAA39482cD4FCcE2eAA6c68EF4B4236fA5683d34",
"0x211Fef38F0c51b28eF8b280D1BC358E8aE5A409d",
"0x53631fffE06Ab7C060430b78C6798adb20590B99",
"0x93409dd0a667D9c981A92e1F7f5571B613d676D3",
"0x3d725c33f217052c624fdd9fa374c29dbc5aa9e4",
"0x281d502F407F8fE61142644d5d9473ec1B2fad61",
"0x4ed98189870923040c1fd6c97c1dad38dc4d45bd",
"0xa400bf317863949d0dc99115f1ecfea14b711500",
"0x4cc277d9b51b6c6217a3f07712a0a60438a42c2d",
"0xe6F4e9111BA1Bc849Aca51AD05aC141da5d433Cc",
"0xefcc81e08b379c7ca1b58cac033ca13424db0b34",
"0x931b0da9a3f39bf59c7addfe50396ae4feb93929",
"0xdE7cff31850541E2EF9a10F73fAAC4A8676eac84",
"0x787982015fc5a8c336b17605878b86f5e0f83fdc",
"0x7696baDd80E8aBE92622b5AE7cb2EfA3A32b10e1",
"0xE0088B6e0145AB4a4d7F3c8c07D3F1344eB439CB",
"0x3a7207c03bc730ca47B757cb3dE68d5737C2F05D",
"0xfF1c050290248EA2b099A6f9158y5C5833383de24",
"0xFd8465266624f85780B6A47CA4C5eDeb193062a7",
"0x3d3801f3EF7Ad80bc3A68790d1Cc001647E66051",
"0xbe6e460bacffe80c96a51eb2a64a5e610eb0bd72",
"0xa400bf317863949d0dc99115f1ecfea14b711500",
"0x5CBc7c22A81AeAa8BdD4425441D73e13614555Fb",
"0x874241EB046a2d2eF9A2965faf313442783b5c24",
"0xA42DBa519069db3aB80bb5d9C9D9a4a55767F247",
"0xa7C9e2c4dcF2e876A5503f08Ccc54F8B52C2943E",
"0xECbe2b8e5DB08C9DBf39fDCB68EF01BA9Ca3BE4A",
"0xC179d9017Fa3A85926442e14cF053478A7D782B6",
"0x1f3Ae8Fa8c337b0CfAbD785197992dcc3F099eA6",
"0x608755935b69e7d28b4891c81e91933a5935d2cd",
"0x22bCB734fce13A16DD0619Bd1bc964F54289C4fC",
"0x260e7AbaEC02f14a9824991ac915F6cd4537cE0d",
"0xD2b170Bd34E2e9182B5B592056Ca611f8e76eB78",
"0x05925Eb93d2401E09BB1e7E308CeB9CFBe27b1c3",
"0x3718E5306c755Cfefc564F841E4294f8B3C056C1",
"0xA17352611a40C0a5b407FA95Ae747848D6379Fe7",
"0x613c220D188f791F5b02666247E74cf523BA27EE",
"0x064f5692B711FE69Cc10ef3848bA659B8B6dCEfa",
"0xC0aA9d7968E8b83a9E84364fb346830F30DaF65c",
"0xe0D1d0150dAE9E81AE4a0b9255c3e1aEeaC353dd",
"0xF8cF866EEF515484e3c2e7338661F91FCa9a550b",
"0xe60f8c189c86f065ff587590a857202f6eded607",
"0xE91Fc21C543977d1b3f227bF49b09A3612371e06",
"0x559B1eEEd0c02dbAA2DdD3A936419917309d5732",
"0x3A34E4da160624ede5f43134781Bf6bD5C8C60fB",
"0x1Cf01b23eA3c51D2C70eaBaA9c44C7efd4A6ddAc",
"0x2813Fc6740a18a9cEae61C805AC698DD3Ca3E57d",
"0xb6597A7F42F3fF464cCa9AAF1B1fF95875de2DF6",
"0x116355bfd22bf39ad87c1a567c461cd920413729",
"0x24279E751B0F0CEc3707B4a94DC7C77EA742041a",
"0x03f9cF9304F769968767058539219CbDC95dCF46",
"0x8A2Cb8DAcEdd1B5222cc094A0019248e6811A827",
"0xfd45BA41CFB3FC218565a197405f427c8c65de4a",
"0xceAd03574E4b930eE871bF8BB49922148A63A8e6",
"0x19F2d1F67254EF46F34C0dA2ec561E142Be2ef23",
"0x5fB7caB834a0f390FdD4EeB22669c8Cbd0D5575a",
"0x40E949E851722407950870e2403f71670A8c4500",
"0xC3F01f6e61C0CedBa21b6E22Ea5A005E7b5ECE03",
"0xC8997513b31A0d49f4a1A67Fe6b233B6643a4247",
"0xd54251d20c1f45e80fb2a950462c5fcc150a01cb",
"0x5620497b517b8c0291e970edba54229ee4a03be4",
"0x4f33cdb58261382da60bf9f2d8c6c1910ad51130",
"0xb859029658b425ae624d6125f70e76d3dbaa7a3a",
"0x67a9a27b2bdb99f0e0dc1b1b743a8325ca3c82a8",
"0x2A54830BAb4447d601BB93CbC6b435107273A99f",
"0x4dDe73181cf976afb296B68EAA635c8b33d704dC",
"0x2CA91a920EEd7d5704E62aA1183318F4A1Ea860B",
"0x679c40857D5Ac27043c40Ee653eC3a8F0eedce9E",
"0x9a5c7c41b6CfAba0Aaff3866d782dF2cc04653E6",
"0xeA8929421168497bD0ac95857bEcE11609CdC996",
"0x115e759fa006cefedee0e2d0b923c38ce257602a",
"0x8b8985a6af9827da29947320ddb1bf5e8db2120f",
"0x68c588EC06291cD6D1A8D3CA1757e7D3a187fa6A",
"0x0A77492A089F0B31Ce6481e606b42fE55CcFBa20",
"0x8e800ae58096dC2B714199aBD9D5b802b7a3235B",
"0x2cb8ad2Ef93aacebf64BD34Bed43980A4986E472",
"0x94D41F055528361368874E3F6DC37605C2d91cFC",
"0x77f36DBF511592801c898FD067B6a9f0b2Ed5c8e",
"0xf719Beb13D8368387008CaD092F1911FDa429a56",
"0x1Db72097e4a2a7E7b7e6128227bC33B97096befb",
"0x90Eb1303e87E824dB55Fd53e00816796D281A51D",
"0x3428A10f1E2962C62fC5cC571E47B45f631adeF8",
"0xE89B01e78a8A71Ef3B31CDa95f2Aa93464f79CDD",
"0xBb340B5A7de9080E32914Cf0779bec51887bfFC3",
"0xAA04B397aC6c16c2E48f32EA98641a95c5E44594",
"0x51c26bB7202c75E5bc00E86A80Ab40F8c6603f8E",
"0x938A65d95FD0e038e275CfEb69e2cB4Bcb432c81",
"0x74654241178f4498d382b01A1315f68d4b9B19C2",
"0x97C97b265989f9CACfbAfaC7D0F4b87b5d92F7e1",
"0xDDb9AB1421E6F1d864c01BA63FB05B183d5ea4d4",
"0xc8d3ddef1fc8240739d60965a102e3f7bf8bae30",
"0x89B7E599630F11fEf1A03D5b99500e0c42c617cA",
"0xE17a15b2790C2Ea33a588e81399eC56EcA266a31",
"0x6E41622A8d17BCf001699E5495d5fa540971b53a",
"0xaf2437658D3f1F622309A78419f55C01Ef61bC46",
"0x8e12aD73AbD670D6F24AEFc2C70be4ae4d233fF6",
"0xd074d9659d7a7765fB44C523d15A718AaDf0AaB4",
"0xD00496217970967e0EBa5883EE0EBD7FB9bFF16B",
"0xbE3C2967098cb72BA828a52540a120C2CD1C5248",
"0xf3267F8ed0085B7eFbC8baB86d0cadec0569b48f",
"0xE692B3Ee367bAB5d6D8303fD2EfD619a338cB71a",
"0xc97B6733e0d0935dbE7e566b19bD1ca8A928A9eF",
"0x81c009C7cc02882b5864080C86e6c7aB86c9c56b",
"0xa2878a27dE2f30f8738D2d6Bc7C1270Db16f23a6",
"0x898e4a1fCFD2C747321BFf89bb84F696a4a92A6E",
"0x136f80D03A7ccfabE456516A0cCa8539A1E32354",
"0xb804524b926BD7Bbe66148B009C3F4547dD53308",
"0xEE3BCA6833215eB2C4140BdF74F6653cCFA2e04D",
"0xDE08a77635ED8a5270133E66Beb8A8923Bc821Ec",
"0x3fF03df72dB069512555D1B8C5e004F034ECf2b7",
"0xF7ec405B406761e4dED2B851110e1bdcA3873cD7",
"0x901c812Ba9C4De51e6CF968ea8c74923c842b271",
"0x45825443B8C7c1da98b833371CC3dA400B3c3460",
"0xE142B18b360d764E77cb06FD37c6b3b322C76b94",
"0xeacA97A135ed887ffa659dF4f2CC632BdC608a9c",
"0x547486014c096bF8eBd43d9B2090db6DAeEF2B23",
"0x144c02f5370Be541e123Fa5cF9083E30Ab7c5a04",
"0xfef1a24044f0624A510DDb496C6afF9B04df5288",
"0xA7b2f12A408eB82Ff26726Cb9356B7f2D8A0dA50",
"0x5411E43184874DfEfCf03784EE49a6E96AcBdDd9",
"0xa9E3c3c54EF598738C406BA014a12Bb018a89A2a",
"0x0CCfA1c3441F3fEbDcEE067bd1Cbe3af7BBB614b",
"0x70413c53dDd693222BD8aa33946b192B7E78A19E",
"0x8DfEC901DF249a0890Fa3aF6948E4C5fBE45a195",
"0x7bF8139Bc5F009447D6d4a3B227899a195218913",
"0x81F73335d1D444b8aD2Fd06520fbf5127bcEB273",
"0x1aABCb0A666a7DAd48BFfd1dE794fe3538c60F0a",
"0xcDbd7Fa89184EA15B1eA9b9bE05012654d022571",
"0xcCEEA703F14950F6F670A917A988114576206C1e",
"0x1764D7d3F56375aC4Ce15170D698d158277644A2",
"0x7E63df8175b03cDecFabE8E0fa425738180b9Af2",
"0xB3419F794308aD4D14BC76e20dAD1cF7dC9337F9",
"0xD531FCF7968cebE0e58Bb23105759d9e448eE91c",
"0x8dDCA55a69842d9Ea1AC039Dc623f747c8Ef6438",
"0xb5750a89f5b23ad981f0d07f2272b72b78a44692",
"0xDb9C1116C70B478c10aEe06A73DB749303Fb2Cae",
"0x66ad060284288905C2fBC65cd7F9E712366F1FcC",
"0xE82D64d6B9c764083eE39C8860791F60512cC7E7",
"0xeb33d2f48Af4ead4422b022C7C944ADbF809B66F",
"0xc53b5611b998e1a31b2Fb3C0A3717c379dAC6053",
"0x3aB0a4156884a82b40f63083bd41eE3D8F339582",
"0x5F0D18be59C63e8A4b8C534E9eE738019C20529c",
"0x349f13AA11bD5224e6c645e252cd40E429cD7aDC",
"0x445446e37cE2311E795b70f9aF2b02E2AB57CB85",
"0x4Fc46B4e2938780a3EA007b1cb0dBf4142373074",
"0x27998cdBD9504308776e8E96929143FBC77Ca8B1",
"0xD05fd249f1b07668B4Eac2eAcE0287F43c05265A",
"0xfa92076CFF5413dD9C0881D72209b7C34D9cDAB9",
"0x08650C270fE3Fd7Fd52A89ab34a4664ccDF05866",
"0x5Aa889B6d4A447bCCDec25A5bDeA4f6e3755E863",
"0x373344830CBde64852D7Da228b121aD313cc09ff",
"0x1989a913819b438254aed4c2FD5Ad8c36532e341",
"0x8441D5e080eF1971096612752C3113965EC8c451",
"0xea632F5b13A52d71C5828F5daF1Ef28Db5a74461",
"0xAE14B4BFcDb31b3945c9fE2E0250e1940e568882",
"0x45a2e7bF644b891dc381fB0a18F049144c32309C",
"0x44E3b47Ec6A58780D759e8f6b371b24f83f7BFAf",
"0x5944F10135E7a2cd35A27FF22a9d8A3943B1ec8c",
"0x0baF224DFB9ffB359BF935e6583A7f143307072D",
"0x6777c195f15B9dB97425B9B800108A548d00f32E",
"0xa64a39a963f1765d7abe69722b8d321764461152",
"0x25908408f15f110fb8819bde2115ff436171e43e",
"0xD37fB313fd31ac8108cD3528DC132384521aa416",
"0xdb5793BCa8F9444Ab187614b8726CEAa839139Ad",
"0xa59f18fb596772df753008f5d1aa18e74d3178f4",
"0x9bc572d1470d6f485662a90ea2f5ea7734f9ce33",
"0x285cfdA4B3b4AA0Cb3e5D278Db374dC3F9978F68",
"0xd345997EC307951d2C07A9398203b0e007039ddB",
"0x4ad2853633a61c71c6f8357e33e2da8e3801e719",
"0x686b70559f4d8ed691df8e778fc6f4bb3309ca5e",
"0xac9e29606d4b609c8de3a42f3ae06190d71107ba",
"0xe13582264f59218e248512c67a63eaae0b77d6d9",
"0xE551717DFd907627D3DdA3B51b511Fe416Bc806b",
"0x3d8805063dab116654cd7914c1a6c0ee31426510",
"0x30cc6dA65331E4E5a79799B97992f9Ba5797fEaE",
"0x1e38bc5530C81527E184Af99bB84D3620b6BF12D",
"0xA1172940FbbB1bA8e868e28DC0ec7f9Bd7C50B2F",
"0x4e450e7FA20a8Eca07Aa41C4206C6C93d03Ad033",
"0x398022Bb297e60DF112d15698266573f9CAC4684",
"0xdc2e91aB2065eE99a2A6fFEDD10D24326909D4bd",
"0xd6180C1656dDE27cc2ae3214717C911e9088E159",
"0xF8ADe90906b3E09734DAe7c2b63Ea409cCD9964C",
"0x54EDF8E46288C72CDd188D5eb0D2F6cb7e48DEEa",
"0xd31a0fcf1DA6a0143afe724DA7A21B91f07c2E0D",
"0xDEE89323fbDA8b5eD0a4143e4D514Df4cFF6971E",
"0xC429d08C5C31d7Db01310ee13516E25E30CA0C3D",
"0xAe85700C87964eE72DFBAA6eb12825Bdb23dbBb7",
"0xaD66051B2DC63444cEd86b41a9bb33dD6f321ccd",
"0x68c810e47300E4BdcefE37c4BA960659167abbE1",
"0xF5a7F4257b4b791D427eAA0696ECaB6d53d3FE30",
"0x758E7D7a8949F5a71776C25F53e3Fb6D74972941",
"0xE91Fc21C543977d1b3f227bF49b09A3612371e06",
"0xb768dc74042435388439d13908A77bb3DB31a33a",
"0x890c343365C5B0380e6f532b82437cC5d0B31199",
"0xC42159149B1715435690FB4089633F1377b93eA4",
"0x1a19816A678bb680AF6Ad6513153fe2A7b8423BF",
"0x3AE0040F3B3c871592D26A18AD79a17Ea5Aaff14",
"0xB6825fe2feE68e8C0d2781d0D963fbFCf6da0487",
"0x0967774931B50f96863a0F2Dcbad2E17AA392A78",
"0x6bfA6b948Af76082F3F2b59A225E2Be147C5c2e7",
"0xF596De113B75dB5093AFE4f92A70E821630b62f5",
"0xe07E7dA4227Ebf6f2bfAB62A3263F54dBD49dB4A",
"0xD66CFE87F7829f996C8e86ae9e1C006CfB942B42",
"0x414826bEb718F2b204Bca67b949604bdC739fCBA",
"0xf03EB43A1b8eeE60DaDE9C9a06e9B83bA361bD52",
"0xC42590551EfB807dC30D18aAE557B5504b018754",
"0xE03B152CB5693230fEB44C17DBCdD6c68C7895B3",
"0xd79a9865F5866760B77D7f82e35316662dEC6793",
"0xB3557ba0d49bb21b43b2a5AC1dB4b5258b8E6640",
"0x94d4d658fE7EB34C7Cc40DA5C85e801Ca2708B6E",
"0x01Cb6466c3576B83CDc707f63D0Ba9d34BA76c3E",
"0x4029642A16de7231eAF46ec7755Ce97B989e8D08",
"0xDe9f863f579FbFAddb270b8995cb24849c47c02e",
"0xB6155B79eD9077d025962F2Ba9d99b9d107574BF",
"0x4F6ef5051D280a8805aDF81b25F770a9da603CB9",
"0x1c4b675c0dc8aff3631863f04fdbc40d228107ca",
"0xbf30DCdecf37FfE20C9094d3f39A843cC9340F32",
"0xD5C325b94e85BbB67Ac895A55C6D30A797E229d4",
"0x0aCA8a11aF5bD467740759FD27eAA1849d7a433b",
"0x49b3C6316EA27215d6e3C956332b32fC01f591d0",
"0x060f95cB9E981063aa23a47Fc9D4701bAFbc4610",
"0x787f7aAC50f99B22c0E625c8D2587a023B15Ef42",
"0x7Fe39864bA7515Cb259222dD19c3aeada6091B71",
"0xa5F7c6df82BB653D803954B6c2a63EcA76c32c36",
"0x77D6842F46d7BD6209B51568218Ba608E9445BB8",
"0xC355a876a8B7e6849eA6F5D11466fa6Db9Bea152",
"0x832e1a56ea5A5fdF1AF6F5BFA92d89C561A7BAd5",
"0x92Ee4FDd53639aFb83c6D15D6c3526775f67B324",
"0x6b4C77227b478ca459696C19923EE56875A9269b",
"0xF090231fF2c14d03a500F1165e2dF7e9f1c69e9e",
"0x224aa750dB14C9eCd07aCaA4c19Cdb0C5C7C9c85",
"0x1e381Fb1A3953f8e9D70606B1B2303e85CB90B15",
"0x543D0F211987c1D1dEd0894e04973c71d7BEc8B3",
"0x2DeD7A9a7C6aa52540E556c9660D505da0b10203",
"0xA8C649116793A76a6fA693ef9469D0A9E69C4fC5",
"0x4000C874094C9d28693Bad47E9fEEe5d527EA064",
"0x74B4b6E58592FD091D5a593E809Dd1F3b9FbfBFC",
"0x649EFBFbD0F27C195114DeCF8F64CeC702042E8A",
"0x7F64AB78ac7AA826F79aeB59ef2a19C7d97431F3",
"0x2A25a765b588b5684DaBef6Db6374e7C932FA139",
"0x9Cbf45642acf39B23a0AeD84c319C46B169a05F7",
"0x049Fb92F659b9aD505F86AE2FF5cD57B4324612c",
"0xd2a5ab6d3fac75eeca94de73948e5fc376b269a5",
"0x99c11D0E6e131Cd110256F283Aefd831841E302d",
"0xD4368982ac2FffAFa917b83CA0eFF2EECC0affcD",
"0x4b2E3c503bf6B2C42851144B21a8d64545686d0E",
"0x49c9dD2D1326154FA9F1Ecc467E20078bF81f9ee",
"0x021DDbf92652395561b9DE1EEB0F060117bD9515",
"0x9294FF89BB5f22422e41598152dB9A6bD13C45F4",
"0x8079dbeBe1b3D7c3Db20aA0F1DaD043635578aFe",
"0xF6974d8d4f20c67Bf0604dA9B1D499239b0853cC",
"0x7869C06Dac2142014643c4BD1a146be189d5Dd00",
"0xA994BA3a26d703E92790993EC22a62c0842C40ff",
"0x1EbDe0d2e17462E28244886c76f2E8D8Cd075095",
"0xdc49105bc68cb63a79df50881be9300acb97ded5",
"0x12c2cf91D1E0F78Cdc464AFeD39432C99A3EaEC7",
"0xED4eBcd8D790824708E16ced47Aa508887a4328D",
"0xa87f4e6bbDe116E4fa7C0C806Af5216B4Fe92C8C",
"0x57825aefC6231b3d9ae7CF2b4773d5E841084645",
"0xfBbf9d1A3A2CF550A13bcE2723466E6c8367212D",
"0x5Bfd181Daa5EEF8d1092a49Ac7d715B1EB937025",
"0xf362F7EA4a7e187b2297dD3851511459FE8A9079",
"0x9294FF89BB5f22422e41598152dB9A6bD13C45F4",
"0xcFa352Baa635269275F8f9cba315ADE6ae4CE270",
"0xfC04c3c5883bA9A80C4226C24B8472b61cCFD734",
"0x12f2347e107Fa44ABa71A5751CA603e2da072635",
"0x950F6ABa3D2c56fEB3Ccc5f5876e11d74A94353a",
"0xcda45b43f9da2b6d5786fce468e3365555260354",
"0x8EeD54fb7ba85807A200f41a27d5D806365FC92B",
"0x897F92e7E2513D2532f37f5C5641A05Cd0F1bDa6",
"0xdbfa0e2fc74f0de93a70a93d0b0f90b634c3a625",
"0x5ACB3525234BA80c2359B50631a2903e5C703832",
"0x6ED3aa2954580989bF9c03Ec80DF8166577606A5",
"0xaaD1F7Ea1bc2083fc1959dB28581556c636A1Bb9",
"0xCD9b50807e5006fe1Abed71F3B9AfdAb8836D150",
"0x9B51F735d58D6FfeaaEC31ed5b5D0Ad881db6204",
"0x47137FB001106BB637a94849f2e4647F7e1129Bf",
"0x4819951Ef30D768046212E705B92993a91E906D1",
"0x1468b28086ec57230D01f580C2D668FF99f1d8A9",
"0xDc39DB2575c4516e629457DA5aF3ba4B9D47EFFF",
"0xCaa7bba72C88C25392e32E91AFcFAB381D4dce8a",
"0x317D1D9Bb49415C6cA1480d9767396ce4E82828D",
"0x659Ad5AdC1Ee9A26597605C54D8a7F413Fa3Be2b",
"0xBb05a82D684569b0e83F981Cd37f3C3d98964b32",
"0x33c2653263f5C0a5B11425731df1dCa62f5f0607",
"0x5F9ff7aaA3F664657873c2C4d6432c6796738c23",
"0x507e9Bf86EE868B46192b5ce9955dC23727B396D",
"0x40CfFF28Ff24c5aE9d22755C8A4870728992dFe2",
"0x62d7B3F0f4561058eb14C1c9f92Cbf2Eb1D938C6",
"0x9ffb6919035f614C40EdD87B479E389134709CD5",
"0xCDC8f2fecF60388E60A8536937131A41F0Fe8388",
"0xB9Db10d327a49096237d3001d4F0EAcB0165cfFB",
"0xdda6f72df08b1411174b547c2cced174946d6328",
"0xEA87C49d2FE5E7D4b448f9e04c72D13E242142EC",
"0xBc528b5916322087739d06050B9F8178246c552C",
"0xC11552A4AfB7106E68B0dA4f022Ae7c7836a1816",
"0x9bEe988bf4Ed024fcb27E6D0a0169D970E0B94ED",
"0x1558A7E06CB93f68E4056bAa4757Ae6f19721BF5",
"0x526738929273D95Fc6152Af15855d1979B812E05",
"0x26013B787AAc632a92483f669E2De85103ad2536",
"0x42Be76bEA8D9F3f1A469d5E091136F223f3530Fa",
"0x211Baa34f304170A7c73dC6f2c39d78D9b129E08",
"0x28E465838922E161D6fcBd2F924Eda947Af07536",
"0xf14DDC04783a97Fab02Df5fFa4eb8B57998b3f98",
"0x08dFE42F11b69c51F42588860568f434D4c69A55",
"0x9058a6dF1674964996bDBe1a35304367e6f0e7De",
"0x5d926c21Bb777515B2bAc425e3A55Fc888fd9ea1",
"0xec2CaE5e5bAe2756Ce8B91441c49c43a019a7cA3",
"0x721422dBC9b9660843E11d9963A924804cbBc4ca",
"0x5704caaEE55D1989bAe866Ffe725618a2A7E6DE9",
"0x62d52a02acb6bf861b7ef7dd0111a99104af4a9e",
"0xc3dA3c9F9f9da324748f61833AbA979533d16D50",
"0x800EdE35dbd1aCe506D7d4EF19ce6CeB4073Db2C",
"0x980383Dc992be06e23bAca07230a591266772D4E",
"0x3f43045400971f893Ce27ECEaE153dE35cbDE5Bd",
"0xf54F7Df31Fc02aCC020710754F50b51dC740b77B",
"0x1fAaD78AF9eac82d733F0F722760f176E274D3E4",
"0xa53cd9a592dcB50AAe0831cE0D64A139d5217091",
"0xE68E5aD811Cda389576A7346aa91e9D912394490",
"0x67837ef53e2968BB0a4C1D5B18fa2f008C172a5E",
"0xA05F4829c0D76e735bF86f677Cd8FB40859a7e33",
"0xF34e890AE30388a2BA11A426e380d75b5fD2Dc44",
"0xa3B0174Fb927C3DA4C14dCEBb17b7B849AE74194",
"0x311F5c586Cf7a6624E2D9FAE37e12724675fAcF6",
"0xDDF04033efD4830a7756bb83cf6Bd0B42E92EfDd",
"0xabd64c5F3ca22E1B7616bF9F7872E306065614b1",
"0xf1EAB8BBE60953d959F545Bd11E9b1cD204f9CEc",
"0x9117a2798182F79CC9AAD4038858b3F8B5B2D6e9",
"0x8fb1bFC55f7be825E0d62c57deAd4B22CE6A7d91",
"0x05aa9bc963341981c75a954c044d05e7ccbf2e48",
"0x52613303B2FB017903b1967a63dCc7F68fCf5bc1",
"0x490C2c433c6865f588a17819773761346285fbb2",
"0x6F25e3b3c133b1DF69122ACc57a8005Fe2103Ed3",
"0x7Fa1c8A0a18627e3f14A8a4793132F8fcb578477",
"0x3D76d0d5EC1f9A4cE01566D0e427137E205D03Cf",
"0x1192A24dF4b73dEad5770Ae55C0E37a81eF2a4f0",
"0xD16ADd8A47B81Ecf3fcCA0fba49720Dce290D859",
"0x4b9791565CD6DDBa0d76e2642E3cb27AbFD0792C",
"0x0C89855b59101272c543d9B480fd7AB30aDae17F",
"0xf612C30104924146C5C11680226671970A4a3294",
"0x6Dc62B5Afc66182aCaa593496234928148617a62",
"0x7D94079B60A6C5a197faD3a22c256795162D654F",
"0x5340f36fb3B5d389cD483a29AEa6051D7B416BB5",
"0x6f850bF8FA7CF8Ab9B94D1960aFd96B108c6BBE2",
"0x6c54AD938261bCc433F0A76927135D0d6f93F034",
"0x23C9D3Fd7De69a8A3634f478ae2d11ef71Aa635b",
"0x5F7a49b8F0FDf0C6dF74c32d514CeFFC32e2f686",
"0x5f96D50ecAe537Ee61Ece457BFE6eE68e5d80342",
"0x3eD79c0CF2F5E67b28F764C38D3A2F45C15340FB",
"0x0870D6261d868ee2E2685BcA8C77C58DCD6FeA7C",
"0xF5e2a54DdE951FeE759A2724Fc654b8b8588Bdb2",
"0x99a3bc74d97ad5c37aecc3918c397b945fbf077f",
"0x734b0ed6b3c3a417c6ed6414c68a932adeeaafe3",
"0x2071946399641f3a845cdc1ab2397fd006d1642a",
"0xa78af4e15b612d3d2575a2618771344787774ae0",
"0xc9a171b619035e5a58222292c896e36736e2f3f6",
"0x54d373bc51576dd105d99786d0ea1467d9663403",
"0x6b2d8856026a67a085b491bec9ffb9039f04245e",
"0x0BC76De77b6A4E410723182fd34769024d94E4A2",
"0xAF4a88Ac58EaA399E7C9f166b84a2837BD83Df56",
"0x63EF215921dF2f268Aa195Fd8C38Aca3d7a9814A",
"0xF2fb793595dB651387048dB4Cb29774E472a7065",
"0x914E8B5aaf0859D2F5c62e0963268c5eF844801f",
"0xFABF98984e0C85802Ada0958ad27D6F4E1C840fe",
"0xb82172f582Aa7759E26c7f0D9D34ef3F50D35f13",
"0x16D82EF8608e226064072731006258C85A2135ad",
"0xA83b6df10cBFcDA9d66E56b617b0C7E9a9521fA0",
"0xC1faA907D226c12e0544Be09140712E48346C780",
"0x54611eB9321B3C18FAA84B85722e06DFff89A27f",
"0xe6713856a4198A9d873668fA7Af60A83eF5D5bfa",
"0x91B30Af6087b0FCa899a205c7eD0657F11A9913A",
"0xe6EF2A19Ca7fB3f0A86Dfe828988C4d9f8a88a74",
"0x738A36991cBeF543A9e05278c6e808cE2FD51EB7",
"0xCbD5392D78d2d3f31598D602d50463d2c91E52ac",
"0xc3B2f0938eE43592E71c21138938A4523D0FC3d8",
"0x8a0A1F0ef822aAC127c2A62Ce261a569E6fF0676",
"0x13Ff768d45808DFd7E4fe9216Cb73B21B8c0BAF1",
"0x14eb8926698d2F450b22dbD1A212483f61eB4699",
"0xD44e414776Cf942112dA00e193b98B42c8cb387D",
"0x6F93a6f6d26c1e706d348274EA9fbd9DFd3918c8",
"0xd34326A05D20ED5f740583138622B0B4e8f57542",
"0xF55794DBeF9bEE9c508Fb10b21cd23A0597EDcC7",
"0x452cCb40AA2102F7F6c108207b931791Ce11A231",
"0x4C3a4C0316Fb4B384cb289a617d36e688572b7C2",
"0x944D4D5f88a003c1a22ed4399331D08816b10211",
"0x94D694FC8Ab3ba44e6DcA1B669b004fa862387e9",
"0xa2Fe857df8435dBfe38A09db74DA876917Cd9168",
"0x51935c185245F51cF57befD92A14B42C9Ce27E3d",
"0x15876D5Cf1f29EfA8Ec0b34BD7e77aCDC5e8E2BF",
"0x224853BB02D1eaCbcb812530c45bFA6fa2a07971",
"0x77D6842F46d7BD6209B51568218Ba608E9445BB8",
"0x97Ea6C6506B6EAa61DC2a360B34715bCCC4c2225",
"0x23852335284F6831ED61609B463cf80a3257e36a",
"0x77d4F2b8129D1599D1958730Aaf8Ca9Af6eAa4C1",
"0x6c4f36f449abe6e00866c20d245d602168c93401",
"0x4872595d87d97b82410ce39700b0165764c68458",
"0x242f943abc7f4dd45793df28964b4e67e97163a5",
"0xa1ca11aa894724ca03dd6543936df5d39d29d792",
"0x403a41d0fD135e43CD86Ca1bD7d8a2018aba028e",
"0x63a44337a04d7624b4af99f3797ce0aae2c3ddad",
"0x3690FbC57D33f9221373BEFDA71dcdD0B4e4C917",
"0xD10140bD8a40f4ABC218171A2DE30C41C3D9af13",
"0x8aac515f54dc61d4935368852d0460ca47dd6d35",
"0x2faDA86e598338C4E9eAf4AFE303402583a98D85",
"0x439BaB1C34f55B9B1a58E68DbDbfA72b59832214",
"0x7cbf3cB1c84F481D20Eb27417F96656673db6474",
"0xF0d2ffDF28D8d715c93A4849d1a3143657A585f6",
"0x1dfd7c9f16d10fecb0A1c85654C4a0Aeaa92c137",
"0xAdFAa4929F0bB544503D322f412EE81B7073b466",
"0x345519d4A5D56Db069f53Eb585D4f6a6Abc7473D",
"0xE6527edCeC7464f9a6FA0E7Bd38aFdD216C63dD1",
"0xb4D133c96D8833ea8A2275a1C0Ce6D2204661624",
"0x1Ee5312803a3fABdFD673e51Ad9eb4E6d648584d",
"0xf4BB0B2e10e1cD05D5834c3052E2d95C7D084021",
"0x0DCd45892694F734A7035aaC6A3F64A8ed30F4cd",
"0x024A2612972BCFA7011b862fF0506fE1187C983B",
"0xBba923e2Ed47345c00621B2ec19c39d97B2Fd4ad",
"0xC96fA40380AF47D481c5A7cd1240068673C3df58",
"0x4d3A78c1C0dDf1233c7b714C8d4D73642241D6f7",
"0xFc8655e45F75ce80DE0f104b69DB2Dab2D1004CA",
"0xf454c82549a24397a1ddb6c5efa1e96da58cdf95",
"0x5B54CE2a3fb3fec38Ad430675f5b5F5c8e189c2e",
"0x21843D40c6F109240E0a4647A1F1232275B3757A",
"0xD4b82F360C138AfF8e4e97CBE490438a7EbAFBA2",
"0x36e033995172ba324a91522f9206ee737cdb7896",
"0x12fc8b743fc7460c867c905beaaf17241ec86c10",
"0xfE7d0921e48D69604ff511Ba471D503fb4A37b55",
"0x06601C9F97094844d1884fB7de843d4C1F9d40e4",
"0xf56EE260bDe5924B2284Cb4BFA115Dcd7e983Cea",
"0x6d11bd6e665b45db60ffa53964ade545b53db5f9",
"0xeF76cD13F4033f1cCF29C7060a2B9eeC6fa2e538",
"0xBCFa34C720DA74d08AC58795a9d1ef6a3a5c635B",
"0xfec2e3Ad9A59A4488c6D829e33Db51a3722c3f28",
"0xf43DE374eb63ACd495bE50Bea2C3928A09402464",
"0xde8ebd67013ce355a0d6f8d3c1624e04cbf4fe3a",
"0xC3A25b9B9Ec3d856bb7179f914b85b6bf19A1C04",
"0xa3e50a842f9defd6cacbf3302bc46cf99ec240bf",
"0x83A26c0abDE7dFE39fbc062c198B9DfCF4BDC782",
"0xD4686af51a2d38e98fb229a5dAb61D2d52aB6606",
"0x0E6F3975314602fb66979b9134Ca2Fedb0C1Db00",
"0x532caff29fE8BB93F4582B504Be3C3bFd0500405",
"0xc2d46235146399D62cEC7859C43C2CFBE54d0E1E",
"0x7A1cc2c595d487823e4F4Af48fde894e2923D6be",
"0x951564300451614ba30eEa0Ec0ab8Bba129d345E",
"0xaf34CC0F0978Dd423f1FaF5dDcE8D940Ed30a841",
"0x101726BCF3c65d210027aFB8F0d7C5e7b7a379Ee",
"0xc04e795031e998f675C829F1c51B68F550301b85",
"0x8848f955B670b11de0b8fA1f316a0A94f3C6821D",
"0x8a6D79CBD78D8397ADe3Dbb5cF9228DB0cD5084b",
"0x284D23de5EB26F45DF735595118466cA24Ef742E",
"0xFe32670a465960D617863c0aF3c47c2943403E48",
"0xB925a7DbfC617280e0c835ba7CD52e4E38828eb0",
"0xbaa99be8f8c3952898e3e1912cb744436e6a53b2",
"0xa68A0600592Ac6C0F7d872D4d4fA35A5a26F81BB",
"0xac0FEfAd0F2947f22Fda8A51B8dd0114535553ab",
"0xA49277fECc656E189Dc812756da0af318c2c71D6",
"0xA603E03c43090e9C1AF6A204d3efa10F95727469",
"0xB8604eb97F4672f393230E158078449EC6fd7A13",
"0xaFE50db325fC20D3A448A5372718C9Da797C7c6b",
"0xc16B0A806F57e70CcCFc10e99409978B5909F1c1",
"0x663A3835ad2228B4D6B1A95A661ef274e38610A3",
"0xa6e3e20088e07d609F2C161C7dC79Ca0380FD257",
"0x452b377D65f99FEf9135E6F4A2aB99B25d6a3972",
"0xb0cCCE4dcE5f66580a0Ab013c07f57e867faBf0B",
"0x255E1e9DA03c3876028849A9890C1A81e1EdB04d",
"0x2D701121C4dDbf5275366D9E14702bcF91ED28D7",
"0xc80DeD6B332336d71b1413678A6060E5deC6b985",
"0x4f51E62A085adcFF4c6E8Ffa0a3706125DB76999",
"0x4e7Edb9777781D569ec61bDbaF0b20945De4245b",
"0xA54f0264bEef86F8Ce13cA1C7e0CFCFC39b69Fc0",
"0x0a300BB1B8d1e57d1AFC1d677b5d40Af4D6864F8",
"0xd2Ca1BDd974Bc64472FF55ffB73AC38183EFd120",
"0x3cEccC5DD8f3551d98D3Ba0C9209dCdc84B22495",
"0xB45528E69116c7E08CFB13671fcF91439918A615",
"0xE5b83AFf79ED6bcE953Df85CF60F1CacD41b2bb2",
"0xd0ce8b74d1ffa1024ac9f830410b1ca6ed0ac353",
"0xD3a181832864c038333868BAC4937a8c3E74D243",
"0x1897A69C3b2A9ea1b5363bc8dBC51237A25d401d",
"0x86009425f87D784dC7899FE23A9d9A9E49A356c1",
"0x816e2367B3CC03B14C10eD60C33394Ce9A86C831",
"0xF48aE433F7615A95C5287F16D9d8441731CeEA4b",
"0xe4e9CeC726b74934445aC9875C1B3FD1941D4542",
"0x1465104944c843252495e81923A696678fC0602f",
"0xEE1B136f48F05eEDcA81B1ad51Ec59faFa0c40aC",
"0x254C9c1c90D6b1c4B0fe911fb1AC43e39bA30b21",
"0x21208E75f62dA41Ca25ca02E66f889ae5b62380b",
"0x87B25396D89c68A2617568e0EE05Feb1cbf3638E",
"0x1130CbEfAa9Afc1105893D10Dd5D8a007fc15A7F",
"0xb8f50aEB471A4D5573c0b43D38e91FF52C8C9E2D",
"0x4Da17A2549563DC28deB3C62c8C1295CC91fd5cB",
"0xCC993c158F5AAC037734C81c126349F727C5e16C",
"0x2e9cAd42eEd8baa20C9C886D66CBBEcf6e951F24",
"0xE1f8dE77C45a128E623C1a4b501254b5AA0Cd0f4",
"0xd1250668C3E8ae6F331BBe0b847978606aF90423",
"0xb346d7C59b3E57D5D200a0c29357A57bfBFA3431",
"0xaF9556ae9572fbbe9D1bf7952340F794f9ffC135",
"0xba83FBAA65F1655d1EdDf1863125469714139768",
"0xafF0DF9FbE45a81E6d0F5737A00E01A65f8C6210",
"0x934b5699D497Aa156be908522E0FDAf5009666D2",
"0x10a1BA21a3B11f24F0Aa93711C00CCA2dA382f3e",
"0xEf71557823CCFAB13d0580524f6C7291771C5CB4",
"0x87feb900b554A63a70Fa7A6E2BC968c1e2521C82",
"0xAa6E2A09Eb9313Be5A4894596bA3a37f66634Ab8",
"0xd31511223e5F49E735896dDc660F829414e3e8BD",
"0xC1154557bdE7AeDD3B617d28d945c7944a06f583",
"0xA3c7b9EfdBb3b226Efb62F5e570302E28F906616",
"0xcBd08A5F071a2Ed53A57dfcebEfAc997d647DE9f",
"0x576530C992deC9C19DaA21A77630Ccc59029c0fA",
"0xa9103be9450db81534dd375c075f0df22f0ab2d9",
"0x631f44b12482290cba876046a796b93161781381",
"0xc7a5AAa937513194058848A73BdC5c5E7a54640a",
"0xd1342e9D433184F6d025aA3C8AD4676275CD1218",
"0x5aefeaa8d95c698d062d8226ac8c8bd8687b8d94",
"0xDF572Db304Ee50166A2e5A002aA658eB7Cd2f16b",
"0x7bd9408845bEDa3172d0Dd190dC7376A3C08d3ba",
"0xFF42171F77B106a92299384968e749ea76324e1d",
"0x29105bd47f3820E8e4077e10BB99947d2A5C7dd8",
"0xE1f27743a4380A9562fDdE7bB98614E3f0F8801F",
"0xef5b6ec3edfb5cda900d4495622fff06429380b5",
"0x135087f50069df010587cd1e2708fb46dc3af45c",
"0x811328dE31832F82032A96f422e1EF60A839c437",
"0x81075184a7DE1f55b75b7EDe7d1f8fd0364ff525",
"0x1eE28DC7571499e62974dA2f70BD855D469b3D94",
"0x91D70029c698b6cdfD8955A34C32102085dEEab0",
"0x19E4cb6Df22169d7c06D22e319C7722d357b8f63",
"0x55432DE4a9b98d2046B4173B8E59a7b540651f20",
"0x0192f4aA2ebfB26fAa985BaDA7f0d302a9976077",
"0x8d0ef08826322861d697c7ddc3ce870e90647aa5",
"0x4cB75146e98562C9d79b31649C6C739e4DCB7CD5",
"0x765260F2Cb9e5839e3153F088066d8EC34a22C6a",
"0x989d4876DA6F646B605F9d8A659CDf65cB055Fab",
"0x5fc097d56fe211f980bfd1951f0948881c3c8361",
"0xc01C3F0aA417deeE26c88d121106fF5fa7028080",
"0x779c137a60a78944459195dbeb8ecd836fa23136",
"0x87B25396D89c68A2617568e0EE05Feb1cbf3638E",
"0x1130CbEfAa9Afc1105893D10Dd5D8a007fc15A7F",
"0xb8f50aEB471A4D5573c0b43D38e91FF52C8C9E2D",
"0x4Da17A2549563DC28deB3C62c8C1295CC91fd5cB",
"0xCC993c158F5AAC037734C81c126349F727C5e16C",
"0x2e9cAd42eEd8baa20C9C886D66CBBEcf6e951F24",
"0xE1f8dE77C45a128E623C1a4b501254b5AA0Cd0f4",
"0xd1250668C3E8ae6F331BBe0b847978606aF90423",
"0xb346d7C59b3E57D5D200a0c29357A57bfBFA3431",
"0xaF9556ae9572fbbe9D1bf7952340F794f9ffC135"
];

// Directly provided GTD addresses array (add all your addresses as needed)
const gtdAddresses = [
  "0x0cB42cA590f632DA35a1D8Ecc79F5A57839C65e2",
"0x0A9173154C1D2F9f840F0eEBc8d42165CBBe2A6a",
"0x78d763817ef44772c1a4919361b85065e2f45d26",
"0x46df2083654a5ab95d37611d0c66059371c005ad",
"0x0B4e71A3769E171c07815EBc2d60879a480E323e",
"0xcB14E11fc0505055e074b07427b0b73A8fdD8b57",
"0xeedcC4D7d9ea1C9c1d1944717a1dFc73663d7D45",
"0x26452fFF1c788261b0fF9Cb66b4EB550DfB0eDDA",
"0xBc8a52163F76ce650D91eBb73d6a975EBB135279",
"0x705fDcB1520e7247aB40DABF45A7333e6551F6c7",
"0x93409dd0a667D9c981A92e1F7f5571B613d676D3",
"0xbE9075DB80c07213A8B651B92A6F2fb38AF44220",
"0x881Dd199f9f14b03D71E9a548e163924f31Da205",
"0xf0Eb57A9cf26e4a3039DadFAb4411030C73fAbA9",
"0xF8663c133eff0Dce8611EA7FE3F10FA7e16f7f73",
"0x45069779D8Fd0e29d95ECe8829ecE15465b41a00",
"0x381E0aA5965Dd44Ca0920577506Bd1CC5677BADB",
"0xE8C996B758C5c6e156d82F45027746D0CD5855d8",
"0xb64482fF80394A73BfC4e08E80D560F19Fd83a31",
"0x3595cf644a1DC7A73bAF43a680bF2D31e090507b",
"0x7DCa6B5093967Aae22b7Ad434af4dF1B8A0BF655",
"0x281d502F407F8fE61142644d5d9473ec1B2fad61",
"0x529fcBFD9D0f9Bd6A4EeeAc07347E13D91B36303",
"0x3d03bab670dfe9ea3b8efbd3fffe7c94284484f9",
"0x0CD0CfaCaEF9E31cb04AD49685f371C53d0B9CB3",
"0xe1fAFABc5A92a72A84F4a8F1B838164F86dbBA08",
"0xf4C4f28786d5BF691e0cD3dBCA0f1e05B584Bf00",
"0x8D60fF5d107D902373B8Ff1c274C93681ca87aE4",
"0x34373dD1968E8222eAf1Ce0aBc4b89027767296A",
"0x4ed98189870923040c1fd6c97c1dad38dc4d45bd",
"0x79Fdacf3C8831433601f268Acab20cFc97220268",
"0x3a95e5407E32A1CC7f6923F3297aF09D2279bBDC",
"0xB88bad0A051a3D8049cdDdfc53B974b9C2c77800",
"0x0211cC6Ee4fc770fdc22abE5f5732fa4e0CbFD62",
"0x2e909Bf45d3242D3a4FAf24c79df00f9B4e87f8f",
"0x06021024F72D7d81cB7eB915FF75aB421750B039",
"0xB9F46c6888beA8eaAc4f5C1b45216b1a51d7dE05",
"0x3dD7DCd5222c12475Eb5Ce135E91618c76B65b50",
"0x1B96c99274b4B7f15b57d31F7Dc16893D85c7ADb",
"0x189A4513be5b9EA145D74F81d84F7180c99FbB7F",
"0xe3238163315DD204F09b54dbC2c4ed5006515a54",
"0xea06d8860981b95a398bf44c875cee58f8196b18",
"0xBed66D7cBc7082Be8f8310d69A68BBaA9346D384",
"0xdeaf6821fe7bf1349804e225a752da4cc6fcc047",
"0x0B04a7C2E548fad423cCf7819468a10B9D959b6D",
"0x0fbbd802256F15Fbd56A6a53e213f5bed6f834cf",
"0x3b41FA90EE11b8A23Aa9e3FBC8069190C033Fdde",
"0x9429619671756BBDe76e48c922a5b80b8F4D1E49",
"0x657621CBA70a2daA0834470f1b48d1F9Bbc5420B",
"0xF4d1f014Bdea142440Deb55f599aB51862AeE069",
"0xE5750a3606E0C9E5Ec47Dea75d124ca1E7F7dCd0",
"0x13f399782768429BdBbA1395175eA3f4244b0B84",
"0x45E9f30aF8545559C92A6008b2FEcd2e42F6ab28",
"0x1669A2295eA0FB3b983D6E6f092B4A0637d64f6c",
"0x2FBfC6d03B10e6c672fDd677F61e3669a9d32884",
"0xF5Ec5Ca3f66dc90b4a96E958F9068caEd134c3D1",
"0x528a2813Acf95fa6B30dCe0288a4895DC6747eb4",
"0x0eA3e5270F55F9e4F36Ef8D7F7C1D34AD1b9aaf9",
"0xf0a7599d1b7c10961f03938e81c1f53d6567a24b",
"0xb03c014486feb6cc8b0a5a77b60e1ca336b4a9e3",
"0x4d6153395FAb8c6243715183a2d9629549ee0557",
"0x5c1f95bfaB1CCf887bb206Ad2ac43915b4cA88aD",
"0x9ece7CB36E56fc18e05fb3d7893E9BBa73Ea7F62",
"0x12fc8b743fc7460c867c905beaaf17241ec86c10",
"0x3129e67980532a355406e91c96a635de9ceefb34",
"0x7BE5a1c4EB2D8fc614367e732336C5a338862b21",
"0xa53ce374860f8134095464e439c9aee2f76dda9a",
"0x152f7d2c3d8ef553bb25bdbd5e64839f37e9d37c",
"0x13C4Cf540C87dB3f2a960Cde5005D6A8017Ca97A",
"0x3e6e94a5e3D119065665A2cBA87BB33Dac4c71ae",
"0x777d7202203a9bfbd9f02bcf9f5354415571bb71",
"0x57e08ae86e9cca5bf41d8f1fc8a4bdbc08233ef7",
"0x688FE872ADC3e80de99e51Af570923192a976Be5",
"0xC388A47f2d72F462Ea4b5D8cf6CfF8281fbAcaDf",
"0x1E254B13C6fA3DF72329CA4831e61BD2e81928aE",
"0xc8defE564099f6767Aef2C4AFD599Ad544179475",
"0x06e736C392E46aC2cB69009B42Ee8DF2aF5c9Bbc",
"0x96460009896c974c33FedfEFf41890abA59FD768",
"0xd65eD3a078d3858518DD13D7484c197e806307C1",
"0x97FAd38042afd569464FF40ECc4353267fA95D68",
"0xBEe111a1336Cc86fD8B143Ef79765b389AECC536",
"0xf9DE78FfBc6b9507Bf3eA35C488223b66261a3e5",
"0x7eD8F68A2B9CdEe1aC5E961257Fe026f7eD403e6",
"0xD3F6364C3b5Cf15e6A01Ad7286bE5f4126bCB9D8",
"0x58703412304cD7D013dae8A761BCa53db4890ee0",
"0x84EC78621734d101E8bEF929Fba11502476EF02C",
"0xB6c7eED531e607bB3Aa0Ed742fEbee40F4eB9646",
"0xA3d2Dac046Fbe8D5DC6792151CF47b0676Ce4542",
"0xC1b8172026Ed6F6ed6659846c0167b54357C4500",
"0xA45F1058BEd58821846533441b5618EF5f00CA5f",
"0x8c7D340e5387fEbcCBD54E56B97197cb0153C924",
"0xcaC64A2627c8A1E9F19166B17E6D70E8494231B6",
"0xaa04f74ab32ca643172da6d7ffdbc489767e2f5b",
"0xD3a181832864c038333868BAC4937a8c3E74D243",
"0xDe4938A59Ef02835969da57DE6545E62dABFd2dA",
"0x6acf24bbc50C2bA2a9cA157765101d8Ea9305C91",
"0x1683742E9488AECaE3b2Fd90E7D5E3Eeadcf11f9",
"0xEB4Bc431406A5ec8ae15871AD5e0d6e952ff9D0A",
"0xB230cF685be0d6A2FA27149F460709f0ecB46a90",
"0x49EA644a4b934EB8117E23ca345a4c3cEDfDe409",
"0xfdceCA5651d0644aB63aE59F85f3EBbe1C98b9ed",
"0x9aE2aCdE5cF3B9e91455f17651dAeb49eD6FC988",
"0xBf6F9b652D922bA148C595ee42BF8BFa9e25Ed15",
"0xED42c3DC073320454cF07c3e929e81D3E9025E5E",
"0x4A74EC3Aa118bf43B653e9F2C8B514B3775B1C03",
"0x32d8aE5F01D833c31fa92c611dd428dA8eEF487d",
"0xE6aBdf24d7d6b81C47faCF219Dd1E21F1508bb85",
"0xB2DB3F37dE8Fd29b7638a553Cd8F5E211830772C",
"0x8935D4CCbf09479EBB86efaDf8077eDBa84AcFb8",
"0x3ea3488D06DD69e145eCad0c8523127562e22d8d",
"0x95AB0297F75bf81F5a7c0695cCaeCb7aEB4dB9C7",
"0x83C06F089f6D3E189E7719991507bDC63fd16d89",
"0xe33671325fc78cbb1c4d6e840d82a21ccddd4f8c",
"0x212219979AE1e9aD1aa8E5e3f2Cc99b1aC54f6a1",
"0x075bbeeE38d096359FE2EB0e4C87eEB6ded01e64",
"0xcb17164626Eb5F313ed721e9eeA5F3EcaF6B1b2D",
"0x97fF4f83c00b2FC4ae8F029F7659Efb13E3D112E",
"0x416b51504f6868722b05ca2281fe82ee4ab255be",
"0x7e52013Af4933a10664d25C71acb4bda693a2A56",
"0x3d8f02628508E0576dF63F1b7F4E9E367cc67400",
"0x6f4b40cb78781c47e239346154fbda8fcb4062db",
"0xF7dCE694a9d0326b6c4c039a02A17e713A009829",
"0x1a2eDaAf756f2aB6b5F8447e66106277E2041195",
"0x0f738c06E1c25000c800376fdfCEAd8a3a150294",
"0xc8a3EF649fE9370C0f255a2A9f0b8173c4413172",
"0x047a543DfA448aAfC39044F63b11741D56c63e19",
"0x2904481851d99c5E667905ae0Dce64E9e9DEA423",
"0xFB6b6f13a9389c213A8040d83966e157F0dFf294",
"0x6653f55D79Fe3CCC55135e1AB9CC771cCF93180c",
"0xc3D8d646F0889AAef65e4Ce36518765f1056C2a5",
"0xD9605434e5e0F96DF2E9FF7c1e13bCbAe58e206a",
"0x39715CF7a0A477377d2B099f1f4C0733f5F63abD",
"0x588D2b61e81EF4866E82bB37338ebbb8469a1f36",
"0xeb0939D7a8555106c187a17C9cFA8409d8Cc2cb8",
"0x554DC4d438d5B025498b5b7d6b88E792aF9aa32E",
"0xC2107bAc162be5A0aD889252b817dcBC70dcf27E",
"0x1e8c37ed67871b6bb632d0e639313b04219dc9b4",
"0x15Aa89b2BD37397Fd5b5C1AC12Cac2C0a8931e66",
"0x9fa3C87Eb4668984b87E185b24ae08759b0f50BD",
"0x4e61712A039eC4A324A6A69A1CE3a06cb368d1E6",
"0x720C003681345dF6Bd8EBF94A18E7a77f021106a",
"0x5B6f101d25E5932f435015E8982eeBbb8e28ddF3",
"0xFeCe874d5CB40D3958d8b43859523ed0a6fA811f",
"0x65fC4Fb27719b3d37020068D257F27770Abd41a5",
"0x2Fc73Fa85B2e4EbbF808Aee9233D18D1C636A2Ac",
"0xebbf8d1a6fd2912a0b5d82a5acca3424d9b40a11",
"0x466Ba4665f63a0CB2F08D0aCf4E363AfFA251efd",
"0x18Cee7f8766D915a51Fa5A7Cb0d662E86F659fC5",
"0x68b5350688C5fFb993e72Af22C18Cce19bdB3D44",
"0x972aa5413B84b92b066E5FDAf72b3CF32A7F7425",
"0xDAcD4012685E4C989Da3B49c17E34778A196fCba",
"0x830785D5878D7E949E120fdE11ba89f8EA70661e",
"0xC28B4A59FA6E9E1A74005a3F9738B8d6C6c4Ab73",
"0x10C93809fD0292EedF68F3C180e9EcefCAAd84A0",
"0x29826c03aca5d709eaaead9d211b6564f788c593",
"0x7Ba290C6146F4deDF7D0DBb6B3f75D4E1B39fE92",
"0xBDB969A121D3Bd526D90996D426e815C1e88652B",
"0x732739De18DF630b354EC365a927a4f4F2BF28E5",
"0x9b566eec7511fa8b9507a9f6fb844090f1d3b9bd",
"0x011cC5b76017925752D335b0A002920626307FbB",
"0x77baD3f0f33A41CF5479A256a3B20D84c6A6d764",
"0xB573D55bB681b091cA01ef0E78D519ED26238C38",
"0xBb9F4a4572281670dE1A8513b8195C161AadcF62",
"0xcFab02262b02bb89137F88Ec723425a628421E5b",
"0x4fecA8309B38850bbf1eed0254F8E8c373d57930",
"0x2717C59dc8aD474e3Eb7fbDC70f811498349d5AB",
"0xaF28a2556EE1c4eda84d7824EB554399c4392004",
"0x980dec362d5eb0c9f063e22d43188a0d31186d14",
"0xDbDD91721768499Ca96e9d299D5e80b87B6dd7b3",
"0x7F8F5Da84114F09b0777035d7fD5642Fad07c1f3",
"0xCe8d8B215B74BC260D8c53b1FD999D68C68484F9",
"0x03605d57F72425C36b929Ab82dd43BD3C8765Ed7",
"0xD610C8B11E2d1CF1C6e43A5034d01165D3b50Bb7",
"0xaf1Dcae6280B1566391E1cCf35bd2402E0c420D4",
"0xe78668cb78e60BDbd1Bd330a0cE4645B04b7d9E2",
"0xcE5B6fb4F28FFFB6C1C4523769187D3f69485568",
"0x7B2C1B566a5842Bc5934c3bb20bF191286b18168",
"0x670bb2589304e973B7A5bF6AC5B542E59434B79d",
"0x4C4F365F6A1F8ccE0f2E649cc6D7641D3353B119",
"0x34371Bc2d8C50A1eca85C4b969dF324BF15117EE",
"0xF2496E45C17a16f13675304F05D48Ffe3836ADc5",
"0x4f39C0DacF7D6AdAe030f15BFD670110FEe33629",
"0xe9fe9EfFbc297a8611f5814b41b28006Ec350C9F",
"0xe6DBC866C4b866B313c0D6843D87ab6eE373Af9d",
"0x942ABBF1F477e03C842160C9d818ae0aDc477FA9",
"0x3541E846b3a4E3D67cE7D4342025A92285c2e291",
"0xbc06499b14de46c14c7879c2db0fc6ac58981774",
"0x3928fa28ab3456b9b74e04156a0327bcb6b14d56",
"0x233ec558d7201e0fd60ac93d8dbfaca28117acf4",
"0x87C80D97c53DC7EDe5746Fe890AA8d496e7632F1",
"0x53206430092a9c23cAB2d8569681283c04060Cba",
"0x87B25396D89c68A2617568e0EE05Feb1cbf3638E",
"0x1130CbEfAa9Afc1105893D10Dd5D8a007fc15A7F",
"0xb8f50aEB471A4D5573c0b43D38e91FF52C8C9E2D",
"0x4Da17A2549563DC28deB3C62c8C1295CC91fd5cB",
"0xCC993c158F5AAC037734C81c126349F727C5e16C",
"0x2e9cAd42eEd8baa20C9C886D66CBBEcf6e951F24",
"0xE1f8dE77C45a128E623C1a4b501254b5AA0Cd0f4",
"0xA696d9A14fB33F77c3591D56acA58178F077F255",
"0x2bc9f668939001c355c114d5df65ec03000de873",
"0x997e77a43C7Ae6A18ED96cF049298EE7E421a195",
"0xC1755B2a9EE0Fd95392f9fb2Ba5Cc6281a0F3FBd",
"0xb8a78102e5aac4ee0bd7ffc1cfaea7830d301119",
"0x52642dC06892103E1A51eb2060Da1CD385BD37ef",
"0x42Dd10D6315EcDfCBfcc8EcEaCCa0BdC9539acf2",
"0xd42bd96b117dd6bd63280620ea981bf967a7ad2b",
"0xd1250668C3E8ae6F331BBe0b847978606aF90423",
"0xb346d7C59b3E57D5D200a0c29357A57bfBFA3431",
"0xaF9556ae9572fbbe9D1bf7952340F794f9ffC135",
"0x19c4f8d95f14540e7eeff9615c2448a493a9c095",
"0xF86943C3CbcfE0B8DdE1dD7cCD6c9eEfCff7cf35",
"0xcd6BA756C51c6ED971B6c951ab94e30d792b9d72",
"0x621e52adBF852d65318EBf835F0A51b00E5B7522",
"0x8440Dec19C345EAd10d3069f2E0C133Bb49e26a3",
"0xF3EbEf48227a8ccB7Fa376096A97448FbeF41fbe",
"0x598d670c44b65d77f8ec2798b1eb3a8b64582cb9",
"0xe5ef5b239b94b2b5983df0be1b6a228284d6514c",
"0xAac3CD65135f22C2B2F5297f7C7E316B49fFE4E6",
"0x9A360644B16eDAA11BD2e0Da4C920910E27Bd7A2",
"0x5ed023a48c63ce43fb4f0046cf603a80158fd460",
"0xe4adb63157836bc86cfbbf51be20185600da3cd1",
"0x64CbdfE99C2D20d81abc639f1386f390343b2dE4",
"0x04D18DDE094dF633c95f2669AB451d44399de549",
"0x4944323b9ff68c86ae7892630b92b9d5de3fbc9c",
"0xf70e6c455dc2d0e49463d769f4339bdcae6514dc",
"0x4625900A22E6FD6B4923f86a6B5AAC9d53F3128f",
"0x680a438e63D1Be66b14D5Fe395F4600da37d4ac1",
"0x2241995B54f96Eb860503F29c499b1899dF374b2",
"0xfF1c050290248EA2b099A6f9158y5C5833383de24",
"0x64e18cFa599218E7a489655b0a82c70035D0ee61",
"0xf5F9e12704147C9bd7da3eeb85AEC0477aEb6226",
"0x8c1405f362A5DCF2B732b025DBBFD858F107c1C0",
"0x662097b3e938bef0017d6816ea643e5d2f53a82a",
"0xff1436ef052e269fead204e56a4bbacf8dbc99dc",
"0x9A71d6936796A42f1A54d565B01c4eaE906C3dBa",
"0x930ca80Ec91fD85e324A0FfAF31872b97679cf36",
"0x5b90335288e399fc4361f64abfa81710cd53915b",
"0x5d90f3E10A6790D080e5C5B5FA5d301244C81CE0",
"0x130bB763AF7448C25F349be4bB7D7E06caDD7390",
"0x9CB39db7B663b8A6a2e038e2F56e58d507A32088",
"0x2b80dbbf028b25fdd5a05f0d7bede01cec2c73fd",
"0x370141f1135705f2ea409bfd2e927bcd91b136ff",
"0x3497a873d2ec18e6a20586ee074bf7762104b78d",
"0x72f89131672bA7299e3af41EBdEA0FF7A7824531",
"0x12077230b41764f6688f8255ab134f3bc7798258",
"0xD2450ED588eCCC35B9fB9EF1C08048124A5Dff74",
"0xa1e00ac58bf4c22c451216c7caefb906f1d20eb5",
"0x069a331934CC060854e3C7cc105b94695F3E105e",
"0xcb74ad1d8ec8c629678698218ccd7e9f2b55e344",
"0xcb74ad1d8ec8c629678698218ccd7e9f2b55e344",
"0x8575e3B5c39e21634576113E97D8f52c714a5C67",
"0x546a0ED69a7DC15f7b6A4Ce28550dbBAA9291Fb0",
"0x832c68F6F9837325B769Aa6bceD1F3E7fa70B333",
"0x4831bd2E5d6ab2568870F963fEF1d96bd8c43546",
"0x78C269eE1F90F93500ddEf91b97d6be2F0bF2d14",
"0x8b82Aab492F9bf28456c91e4BD28633fb4c5B0BC",
"0xb9178353177EDc01a521622bB5db171219f4d608",
"0x66ae19Ee4f75D543875575eAf50a8f4ecf94DD5a",
"0x65Cb3CE9A58227C5aef7522a6e4BD27cdCE9d362",
"0x1875E1BC3F107F5108A93405512BFD44E8F4Fd99",
"0xf51040e6104A86ec66B0ece14E09De7d24a1eC2A",
"0x9000859361C6eC61d47Da29cB26A14CF3417f299",
"0xb4f9a6Ee5821C5C6775E426b32F1D9Dd952B99eE",
"0xc1a3c944615AEbbe6D24e0263bf3c54789A0f814",
"0x3CA5c0714DAA3776b2D8dF1c8083A5EbB00BDFC4",
"0x9074576CD8975458e5956294B20C50c42cD24Ef3",
"0x02E4aC4bC0E8341784Ec8b7712454f8Df1f9F368",
"0x9C46685ed424f437D3e70Fec23303b8C6fCA2A97",
"0xe286557BBdC4a17d4e206987feB5Cef15A383B4a",
"0x1CC97DBD54aafB4F9278E20448c26348BE449748",
"0x81C2a33Ff66940FA6E05572FC4cE93A98A9185cE",
"0x4d8B2A58d8474ad8920cBd756612eA389bF0581d",
"0xde68a3155d64b5f6b8fad0e4e5cf4fc17ce5346d",
"0xBC6d4e5f70F950E8e7138a62Cb1AB49c65751C47",
"0x2cb8ad2Ef93aacebf64BD34Bed43980A4986E472",
"0x3428A10f1E2962C62fC5cC571E47B45f631adeF8",
"0xE89B01e78a8A71Ef3B31CDa95f2Aa93464f79CDD",
"0xDca95d7F7533548a9124EdC6959Fcdb2e56f0B07",
"0x97C97b265989f9CACfbAfaC7D0F4b87b5d92F7e1",
"0x4186bF0f5c96697EC38aA5A4ff0f2360e8Fc7333",
"0x3f49f70f253b511379a9f64e9bcf73016b3e64f2",
"0xa591f4f2483f7dba08440baa01ccf79a24a78843",
"0x4B35A52E0ae2AED2f87D242088edaEFB2c4304AE",
"0x3f7D0ec4807fD91D762Ed8A9e969874cc1b3509e",
"0x9ac37666237e423611e47683f1e2ef2054170b5c",
"0xbcf421ae5f87c803e37a897c4c61300ee9ee25ff",
"0xD0c087eeDb1A171fEEd0D8790938A563Ae65BD5d",
"0xdE5b355E50Eae986f67b9F0a3E28575a2Dbc3fae",
"0x7Bb996fB0d45B425D1E20EB32Ac2eBb24a7CB300",
"0xe0D1d0150dAE9E81AE4a0b9255c3e1aEeaC353dd",
"0x35C1147AE493d82f3b07450Eb174374214bCF4cc",
"0xe06b37206ABb46630e6123b71834F2a6741d1442",
"0x851132da8efC0b298CDd9EAEBE4009243722AdaC",
"0x9445c1C6f6C8311da7100349328d86CA1810350d",
"0x4CEf98D34e5BBe12491CeF4D565FFBEb128A4B46",
"0x2741023a81A16790cA55e5a41c5c108eD7460aB0",
"0x0684518BFe8014a5A72E1695DF4cB6b3f7274bB1",
"0x214d3798d0Ec8FC8a7907FdEbB64f7b8ebF7CB02",
"0x3A01602A9E57B2B007635057e9CDa96080d7c2Dd",
"0x6Dde06E86106398AAfDC83D1869db99aa8CFe639",
"0xF8c8b7a061d57e09e4fdC6E8e66054F08570EB31",
"0x361AA2F20133E11f7207bCF5B7E458E15Ede4f82",
"0x33ED0AdBA2D99315Bb1C97D11f8089245A3658E7",
"0x1A75d302F28E2A3Ccb37D16D97Dc104E95e880aF",
"0x7A5D1c67A6a3d8deE102962f8D654007aeA7ce8B",
"0x229125775ca4e18dadffedad3daa35e13283cbe9",
"0x06183b3e91a63fbacb32f66579cee39af0e4ffd9",
"0x687663Afab41C2ce0794A6293ee768b26D2901Fb",
"0xa006c8db0a78dafc94338764cf935f8fbfc9674a",
"0x30A37889e50D288d8C0D9019162e8Ca207d110F0",
"0xe9e9c16e16348360a11621a5662611d4088140b6",
"0x13552eacc56270bc71ffeefd02b2013d61294cf4",
"0xB5a30239d0D97c20E859e4D4ac351c5Aba495243",
"0x0528Bb04257729f633Cfe065D16686EB538c437c",
"0x06fAC54086936c4F3dc37Ff3E47f45cf59105825",
"0xb8C0ac27Fb00BDC899F92f9B98Ee3a7C0F1caCcD",
"0x4C5c003Eb8082D1Db5228E6EE173b37b67BD9688",
"0x5b6E57bAeb62c530cF369853e15ED25D0c82A865",
"0x070fa4f484E79B1183B5f3043bbe729A110047aE",
"0xa31d42226E2E7293d2C2ACb3cC719337edaA069D",
"0xfa4a341880c7dFE903e07d913E81FE0b16677650",
"0xc9288c986afcda8453ae8aede9621235645ff78f",
"0x7782841f4E52EB257db880014Dc83046d6553e31",
"0xd2420A97280904621AE378B1f32DC06630Dd8A35",
"0x2f4AD6FB4ca9994A8591BF149f2c3fE4a67f8F8a",
"0xC179d9017Fa3A85926442e14cF053478A7D782B6",
"0x658cD789b3526Dc81f1134b8De17260C21317e87",
"0x72De7aDe58bd84764a2E252bc0F64dFD02d23279",
"0x15f6fd10CBd13dE4Ac5a0fa56a3691a22b3a1166",
"0x01180F770161351e946f6665befA13beb56898fA",
"0x8520201Ec6Ab08AA35270efDCF28b51a826bcd97",
"0x17b8909a6fFA2C0255BB0dE6Be9E5A4A768690c2",
"0xb8EF5c01B9eEf0a166a6eb1708D9b17351d7cE0B",
"0x8195fCB431ec1c21Fa88AfB4523590eD5a843C0f",
"0x56e1b883a7ee8c948d014aF899E2710B8B89dE6d",
"0x9e702dd2e1Ecc7593838576b100882002D7f6cA3",
"0x8081A75141dBC89f70d31eece08FF12cfe105e43",
"0xF4b5813db24Fb8f8D8adFf870C17cE32e166F17C",
"0x68620ebc2e9fb80556aa68a29fdb14559013c04e",
"0x578A64A02024920c68742e16E7e055D40CA543C0",
"0x322b4D1Dea0213047Ff23Dd7687b6E0FCC78e546",
"0x069C1d6161530a5B007DE1E8a48261f2B137EeeC",
"0x0485e97B948E1Cb5688251f8F3b4C86751512e60",
"0x6Bc55429118fecDd60170Dd41793d7E3494cF737",
"0xeD1BC636FBcb3Ad13216281b98417e945775F1a6",
"0x536587c952a474139218a065f1E17e60A7aF9c7C",
"0x4816c7569476715537fbd4d195af01781d0a0176",
"0xf75ffaa90A5e35D010Dd0f4Cf428DC40e4248B92",
"0xC9840eB190206Cdb2ebe50279e22D55d2864c67b",
"0x8326E98c55731393EfE509860FE7793584068381",
"0x31AB53724F694D6950665F1630d4C34D216E42f9",
"0x9cA41068c6c56550720A68F8D67EDC6ae94B8641",
"0xe9E8C9364612d991Aaaa193175F2A3021aE093F8",
"0x1992491A9470bBbe92BEe040042F6c4E1b76295b",
"0x564c52535e60652e77B1B4961A1E92848bFeBa15",
"0xc4aF88ea56302d8681c280881811448cF218Da5c",
"0xEEaa1b87C08707B0b921F1fF192F4fF1458837eB",
"0x7A52ed1c3fF47D9613F67e93A3886FFF16c3b466",
"0xe4eb1cbd1dc7fe36bdfbbdd0ce36de01352d7975",
"0x391018a054c9c55D534a153Be308711AC4b6C116",
"0xEcA85146092c3BE0ae36D36F39b3D6FE5A7E4B69",
"0xBaC7d9371E8Ef0701Fa2159ed15bf940beBcc330",
"0x9E69eA37040951740c71d1E5e285d6D00c5BD4F1",
"0x53E642b2B4f78AF7aCaf69529ced9bf8805c3A68",
"0x8dF6f5dee6DbaFF07cbC3ec393f9fBbEaaa799B5",
"0x965266f99C69968f40FFD02956A7C9587FcDE0Ba",
"0x5e2b61716f7aE2Cc9A414671EA6AfF0880Be6560",
"0x8195fCB431ec1c21Fa88AfB4523590eD5a843C0f",
"0x6b2d8856026a67A085b491Bec9FfB9039f04245E",
"0x885B0DC9774Ea8bc6E2B9b27F8e53175a8001E08",
"0xcF02e9813C68c6d616c36f72189AfCf979E13E41",
"0xF7054BB3300341BEAbdbDC854848063DEd0CECb6",
"0x079B9acE9351db93B0aBF30A40A4aC06d2D06270",
"0x2EBB56555EA378e6A733Ff0b2Aee9BAA16b30593",
"0xBE21457B5697262088560EFa587CdA59B8634cDd",
"0x23799A9AA6130778939231171C838A5Bf872bB4b",
"0x7e20daf9834023AA24684F0C2E5686E14d920c53",
"0x40a14d2dBB1d16F0F4007707EfdeaAA23c7DEEF5",
"0x5AA20fd8FC4E06596Be15258801F28eD9a0652e4",
"0x40a14d2dBB1d16F0F4007707EfdeaAA23c7DEEF5",
"0xDFabEce9789833f722113727fe49360b14d815d4",
"0x12Af4675299E0e72D12695fA9c7d9e3195D32380",
"0x48D9742aF29eB0805cA5402a37B6C21B48491b13",
"0xA2a12dBacB94c47628ABf040A9Ec87D2eb7a2857",
"0x092d79FdD0bC17d70863eB2206eDDE5a0Bfdb997",
"0xa0Bd5C3838342961e09dc2225C340F4A1818aB6D",
"0xc7127b245A31139892777B925E92BB8Fc4A2AAD7",
"0x2f024B3EcCfE52150E55A147Ff4b3fC8640871B5",
"0x1bfE8143B7343Bc6F31aE5B53C6a3E0c825fa3DE",
"0x854D33F336157ccF5d05B6cAfB034D76e435ed04",
"0x7a5B1FF7A1be8E36227C8DaF65550dD312A12d53",
"0x69a0FC963016741BfB83bCaEF86f55A80126D47d",
"0xb0075A0FDf597d6d179Ab2Cb381c63e4550cBC3D",
"0x194F822352d561dae662CaBC3C1765690E02c1bD",
"0x6898428535A3DeEB400B2278dE7412beC7f49Af9",
"0x6D11bD6E665B45DB60fFA53964adE545B53DB5f9",
"0x32b89C6ab1d11103673414f69E4A791D4BDcf615",
"0x77bB13BF886628eB1Ff4a48668d1F35aa19997AC",
"0x964E1e21Daeb6033a51c404882F47d572350ED4D",
"0x9E7CfA2eaDCa5A46bC44aDf2D3e4Dc8BF5539874",
"0x242a1F9bB7348936170B7125F2286f107E4C0729",
"0x14475F3B886634dcD501EBc0Fd555660946F52B8",
"0xE0BcbfB849079a167721834356BAe529851b7B0B",
"0x1B70fE9a61192DeA4a34b0956d3F1Ddf344899cc",
"0x925FA66A206D7525CC34628A239C756a8A6633c0",
"0xf8B3f7526fb2F6467b4C1c1F7EbaC4977E181cc4",
"0x6CA03f59511b7151Beb5a378A4Fc4a6Ba7E807A5",
"0x6c34Acf38B4Bd5793BCcB59f816F292028551d41",
"0x461DFEbc8564821B2ecBb7868A2C58AEeC4316f9",
"0x18fC5bF38853d8f9E8dA8D1Cc3fA7C339FBea534",
"0xed7Ee1A158cE4f1Ab121F646605D55D269d227F6",
"0xCD60D10D9aeFdb480aA38a592E759a44dCa502b1",
"0xc775B6589b6417fB5Ea4bebF87727655c63F0637",
"0x470D3f3072370a67727225CA3a3AEa3cff8c439e",
"0xd0ab651368836f98dC35f2A6989A3636c8EB1157",
"0xfeCd0Cb6eE16c61bc7e8C5C7a464F6b3a8f1A075",
"0xcbF3f36a83b80C546C7e56AbD7fBb547946b6eD4",
"0x44f9009B7810f15f1FCB22C7f4a5cD18aA9A6fAA",
"0x96E0A665f415462B7593B0C8208EB25f3daCA914",
"0x1bF520A515E2BEA8b1280E235bcdA15c0F70D4BF",
"0xD3A8CD0d1E1087fA854Bd3f7466Aa4aabF32C218",
"0x4F67f52147c6BC03563772Fa3D7AF3adFfb92110",
"0x2888Eecd915fbb6BE65dac1d51cD48a340E3Ab1f",
"0xD4D700ecf23d18F197Ec10846C7fE0E69Cf98D70",
"0xDF7494B3C3aa53EfD45633A38F8d64214ABcF7CF",
"0xBc0E7c2EeE1ff1c61449fAD2d11923a1C0097f2B",
"0x49c6617f346d1D085005DAD229B8BDb248e86458",
"0x2D879B715F78fb13F8f9f2A884C31b6Cc73e3965",
"0x08b6998A2E283849a6B836b16102Dae528CaC9d4",
"0xa02969462a47298Bc8F6CCFb44EbC0c662A712E6",
"0x23631b12402FE1B2404fcCFAB01557269B0DF108",
"0xAD6B1D5C06A9C65270831cb187Eb630bA9D936CF",
"0x483b0b488cf75848cfb63a97f16064dc79f82c9c",
"0x2b585891B9bc6183f70e12Ae6413280E3304Ac07",
"0x027fc636db3ced7342581baed6eee79f7d1123c3",
"0x4cb75146e98562c9d79b31649c6c739e4dcb7cd5",
"0xcB1ba5766D28c5A29ca70d3Cd5794Ada375D16de",
"0x93475Dc5F1A3e7a30eB3Fccc23615f6d9C2BEB88",
"0x3589E1522B9F8CdaD5cF83F7d56A0d7bA7626cca",
"0xdbB51E66e13dBb8Ae19F017BF128280eF7deEa9C",
"0x75dc757Da6C46fe5514c210f354b849CAd6018b9",
"0x5bd98E6aFc23CB0fb152E5BC1e6D509Fcc11e3D0",
"0xff05163ace5931715c0e905ad613ace82d52d3a1"
];

// Clean all addresses once
const cleanedFcfsList = fcfsAddresses.map((addr) => cleanAddress(addr));
const cleanedGtdList = gtdAddresses.map((addr) => cleanAddress(addr));

function Form() {
  const [buttonImage] = useState(Send);
  const [formData, setFormData] = useState({ Address: "" });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Use the inlined and cleaned FCFS and GTD lists directly.
  const [fcfsList] = useState(cleanedFcfsList);
  const [gtdList] = useState(cleanedGtdList);
  const [whitelistStatus, setWhitelistStatus] = useState("");
  const [popupTrigger, setPopupTrigger] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate the form input; assumes a valid Ethereum address starts with "0x" and is 42 characters long.
  const validateForm = () => {
    const newErrors = {};
    if (!formData.Address) {
      newErrors.Address = "Provide a valid wallet address";
    } else if (!/^0x[a-fA-F0-9]{40}$/.test(formData.Address)) {
      newErrors.Address = "Provide a valid wallet address";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      // Clean the entered address
      const enteredAddress = cleanAddress(formData.Address);
      console.log("Entered Address:", enteredAddress);

      // Check if the entered address exists in the FCFS or GTD lists.
      const inFcfs = fcfsList.some((addr) => addr === enteredAddress);
      const inGtd = gtdList.some((addr) => addr === enteredAddress);

      console.log("Found in FCFS:", inFcfs);
      console.log("Found in GTD:", inGtd);

      if (inFcfs) {
        setWhitelistStatus("FCFS WHITELISTED");
      } else if (inGtd) {
        setWhitelistStatus("GTD WHITELISTED");
      } else {
        setWhitelistStatus("UNVERIFIED");
      }
      setPopupTrigger(true);
    }
  };

  return (
    <>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="address1">
          <label htmlFor="Address1" className="addresstitle">
            Wallet Address
          </label>
          <input
            className="addressinput"
            type="text"
            placeholder="0x..."
            name="Address"
            size="40"
            value={formData.Address}
            onChange={handleInputChange}
          />
          {errors.Address && <span className="error">{errors.Address}</span>}
        </div>

        <button
          type="submit"
          style={{
            background: "none",
            border: "none",
            cursor: isSubmitting ? "not-allowed" : "pointer",
            padding: "0",
          }}
          disabled={isSubmitting}
        >
          <img src={buttonImage} alt="Button Icon" className="buttonform1" />
        </button>
      </form>

      {/* Popup displays the whitelist status when triggered */}
      <Popup trigger={popupTrigger} setTrigger={setPopupTrigger}>
        <div className="popup-message">
          <h2>{whitelistStatus}</h2>
        </div>
      </Popup>
    </>
  );
}

export default Form;
