import React, { useState } from 'react';
import Popup from "../Home/NavBar/Popup"
import Form from '../Home/NavBar/checker';
import './NavBar.css';
// import YelloMkp from './mktp.png'

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
   const [popupTrigger, setPopupTrigger] = useState(false);
 
   const toggleMenu = () => {
     setMenuOpen(!menuOpen);
   };

  return (
    <>
    <nav className="navbar">
        {/* <a href="#" className="navbar-logo">Logo</a> */}
      <div className="navbar-container">
      <a href="https://linktr.ee/panicofficial_" className="linktree_g"></a>
          <a href="https://shop.slice.so/store/2160" className="shop_g"></a>
          <a 
                       className="walletchecker_g" 
            onClick={() => setPopupTrigger(true)}
          ></a>
          <a href="#" className="mkt_g"></a>
          <a href="#" className="mint_g"></a>
        
      </div>
    </nav>
    <Popup trigger={popupTrigger} setTrigger={setPopupTrigger}>
    <Form />
  </Popup>
  </>
  );
};

export default Navbar;
